/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React from 'react';
import root from 'window-or-global';
import {withRouter} from 'react-router';
import qs from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {getMessage} from '../../components';
import {isSameObjectsWithNormalFields} from '../../utils';
import {getCmsSlugForTagQuery, getHelmetMetaTags} from '../../utils/metaTagUtils';
import {generateListParams} from '../basePage/BasePageView';

@connect(mapStateToProps, mapDispatchToProps)
class AssetsView extends BasePageView {
    constructor(props) {
        super(props, null, {
            extraFilter: ['keywords'],
            isAssetsPage: true,
            pageTitleCode: 'messages.com.epicgames.plugin.store.title.search'
        });
    }

    componentDidMount() {
        const listParams = this.state.listParams || {};
        const {keywords} = listParams;
        this.setPageConfig(keywords);
        super.componentDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        super.UNSAFE_componentWillReceiveProps(nextProps);
        const {
            getCmsPageData,
            location: {pathname: oldPathname, search: oldSearch}
        } = this.props;
        const {listParams: stateParams = {}, extraFilter, staticParams} = this.state;
        const {
            listParams,
            location: {search, pathname}
        } = nextProps;
        const searchParams = qs.parse(search);
        const keywords = searchParams.keywords || '';
        const oldKeywords = stateParams.keywords || '';
        if (keywords !== oldKeywords) {
            this.setPageConfig(keywords);
        }

        const isServerSide = root.__server_side_render;
        const queryChangedOnly =
            pathname === oldPathname &&
            !isSameObjectsWithNormalFields(qs.parse(search), qs.parse(oldSearch));
        const generatedParams = generateListParams(listParams, extraFilter, staticParams);
        const stateChangedOnly = !isSameObjectsWithNormalFields(generatedParams, stateParams, [
            'effectiveDate'
        ]);
        if (!isServerSide && (queryChangedOnly || stateChangedOnly)) {
            const cmsSlug = getCmsSlugForTagQuery(search);
            getCmsPageData(cmsSlug || '/assets');
        }
    }

    componentWillUnmount() {
        this.props.setConfig({
            keywords: ''
        });
    }

    setPageConfig = keywords => {
        const contentHeader = {
            code: 'messages.com.epicgames.plugin.store.assets.page.header'
        };
        if (keywords) {
            contentHeader.code = 'messages.com.epicgames.plugin.store.search.result.header';
            contentHeader.args = [keywords];
        }
        this.props.setConfig({
            contentHeader,
            keywords
        });
    };

    getHelmetElement = () => {
        const {helmet} = this.state;
        const listParams = this.state.listParams || {};
        const {tag} = listParams;
        const {cmsPageData, isLoading, messages, locale} = this.props;
        if (cmsPageData && Object.keys(cmsPageData).length) {
            return getHelmetForMetaTags(cmsPageData, 'UE Marketplace');
        } else if (!cmsPageData && !isLoading) {
            const pageTitle = helmet.title;
            let descriptionCode = null;
            const tagCode = tag ? tag[0] : null;
            switch (tagCode) {
                case '4910':
                    descriptionCode = 'epic.ue.marketplace.meta.free_month';
                    break;
                case '4906':
                    descriptionCode = 'epic.ue.marketplace.meta.free_collection';
                    break;
                default:
                    descriptionCode = 'epic.ue.marketplace.meta.browse';
                    break;
            }

            const helmetTags = getHelmetMetaTags({
                title: pageTitle,
                description: getMessage(messages, descriptionCode),
                locale
            });

            return <Helmet {...helmetTags} />;
        }
        return null;
    };
}
export default withRouter(AssetsView);
