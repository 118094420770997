import React from 'react';
import EventSaleView from './EventSaleView.connected';
import {AssetActions} from '../../actions';
import {generateFilterParams} from '../../utils';
import {generateListParams} from '../basePage/BasePageView';
import {getDefaultSearchParams} from '../../constants';
import qs from 'query-string';

export default class EventSalePageView extends React.Component {
    static async getInitialProps(match, {dataPreload, parsedUrl, store}, token) {
        const staticParams = {
            saleType: 'event'
        };

        const extraFilter = null;
        const config = dataPreload.config || {};
        const priceRanges = config.priceRanges || {};
        const engineVersions = config.engineVersions || {};

        const searchParams =
            generateFilterParams(qs.parse(parsedUrl.search), priceRanges, engineVersions) || {};
        if (searchParams.keywords) {
            searchParams.sortBy = 'relevancy';
            searchParams.sortDir = 'DESC';
        } else if (searchParams.sortBy === 'relevancy') {
            searchParams.sortBy = 'effectiveDate';
        }

        const generatedParams = generateListParams(
            Object.assign({}, getDefaultSearchParams(), searchParams, staticParams),
            extraFilter,
            staticParams
        );

        store.dispatch(AssetActions.listAssets({...generatedParams, token}));
    }

    render() {
        return <EventSaleView {...this.props} />;
    }
}
