/**
 * Created by Kaku.Guo on 2018/10/25.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {generateRoutePath} from '@epic-core/common';
import {withRouter} from 'react-router';
import {Message, NavLink, Link, LinkDropDown} from '../index';
import {debounce, loginInterceptor} from '../../utils';

const mapStateToProps = state => {
    const isLoggedIn = state.get('user').get('isLoggedIn');
    const globalBase = state.get('config').get('globalBase');
    return {isLoggedIn, globalBase};
};

const HeaderNav = props => {
    const {
        categories = [],
        eventSaleLabel,
        isEventSaleVisible,
        isLauncher,
        locale,
        sellerPortalUrl,
        supportSiteUrl,
        tagFreeForMonth,
        isLoggedIn,
        globalBase,
        history,
        tagPermanentlyFree,
        tagMarketplaceCollection,
        tagIndustries,
        tagArchitecture,
        tagAutomotive,
        tagSimulation,
        tagFashion,
        tagFilm
    } = props;

    const getEventSaleLabel = label => {
        if (typeof label === 'object') {
            return <Message code={label.code} />;
        }
        return label;
    };

    const editedLocale = locale.replace('-', '_'); // Help site uses 'en_US' format
    const categoriesWithoutRecent = categories.filter(cat => cat.path !== 'assets/recent');
    const [ueOnlineLearningCategory] = categories.filter(
        cat => cat.path === 'assets/onlinelearning'
    );

    // sort the categories alphabetically
    categoriesWithoutRecent.sort((a, b) => {
        const catA = a.name;
        const catB = b.name;
        if (catA < catB) {
            return -1;
        }
        if (catA > catB) {
            return 1;
        }
        return 0;
    });

    const getCategoryElement = (cate, idx) => (
        <li key={idx}>
            <Link to={`content-cat/${cate.path}`}>{cate.name}</Link>
        </li>
    );

    return (
        <nav>
            <ul className="header-navigation">
                <li className="home-nav">
                    <NavLink to="store">
                        <Message code="messages.com.epicgames.plugin.store.header.home" />
                    </NavLink>
                </li>
                <LinkDropDown
                    className="store-top-categories"
                    renderPlaceholder={() => (
                        <NavLink to="assets" keywords={['assets', 'content-cat']}>
                            <Message code="messages.com.epicgames.plugin.store.all_products" />
                            <i className="fa fa-angle-down fa-large" />
                        </NavLink>
                    )}
                    renderDropDown={() => (
                        <ul>
                            <li key="showcase">
                                <Link to="showcase" className="special-category">
                                    <Message code="messages.com.epicgames.plugin.store.header.featured" />
                                </Link>
                            </li>
                            <li key="new-content">
                                <Link to="new-content" className="special-category">
                                    <Message code="messages.com.epicgames.plugin.store.header.new_content" />
                                </Link>
                            </li>
                            <li key="browse-divider" className="browse-divider">
                                <Message code="messages.com.epicgames.plugin.store.header.browse_by_category" />
                            </li>
                            {categoriesWithoutRecent.map((cate, idx) => {
                                return getCategoryElement(cate, idx);
                            })}
                        </ul>
                    )}
                />
                <LinkDropDown
                    className="store-industries-categories"
                    renderPlaceholder={() => (
                        <NavLink
                            to={{
                                pathname: 'assets',
                                search: `?tag=${tagIndustries}`
                            }}>
                            <Message code="messages.com.epicgames.plugin.store.header.industries" />
                            <i className="fa fa-angle-down fa-large" />
                        </NavLink>
                    )}
                    renderDropDown={() => (
                        <ul>
                            <li>
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagIndustries}&tag=${tagArchitecture}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.architecture" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagIndustries}&tag=${tagAutomotive}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.automotive" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagIndustries}&tag=${tagFashion}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.fashion" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagIndustries}&tag=${tagFilm}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.film" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagIndustries}&tag=${tagSimulation}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.simulation" />
                                </Link>
                            </li>
                        </ul>
                    )}
                />

                <LinkDropDown
                    className="store-free-categories"
                    renderPlaceholder={() => (
                        <NavLink to="free">
                            <Message code="messages.com.epicgames.plugin.store.header.free" />
                            <i className="fa fa-angle-down fa-large" />
                        </NavLink>
                    )}
                    renderDropDown={() => (
                        <ul>
                            <li key="epic_games">
                                <Link to="profile/Epic+Games">
                                    <Message code="messages.com.epicgames.plugin.store.header.epic_games_content" />
                                </Link>
                            </li>
                            <li key="ueOnlineLearning">
                                <Link to={`content-cat/${ueOnlineLearningCategory.path}`}>
                                    {ueOnlineLearningCategory.name}
                                </Link>
                            </li>
                            <li>
                                <Link to="profile/Quixel+Megascans">
                                    <Message code="messages.com.epicgames.plugin.store.header.quixel_megascans" />
                                </Link>
                            </li>
                            <li key="tagFreeForMonth">
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagFreeForMonth}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.free_for_month" />
                                </Link>
                            </li>
                            <li key="tagPermanentlyFree">
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagPermanentlyFree}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.permanently_free" />
                                </Link>
                            </li>
                            <li key="tagMarketplaceCollection">
                                <Link
                                    to={{
                                        pathname: 'assets',
                                        search: `?tag=${tagMarketplaceCollection}`
                                    }}>
                                    <Message code="messages.com.epicgames.plugin.store.header.marketplace_collection" />
                                </Link>
                            </li>
                        </ul>
                    )}
                />
                <li>
                    <NavLink to="on-sale">
                        <Message code="epic.ue.marketplace.categories.onSale" />
                    </NavLink>
                </li>
                {isEventSaleVisible && (
                    <li>
                        <NavLink to="event-sale">{getEventSaleLabel(eventSaleLabel)}</NavLink>
                    </li>
                )}
                {isLauncher ? (
                    <li>
                        <a href={sellerPortalUrl} target="_blank" rel="noopener noreferrer">
                            <Message code="messages.com.epicgames.plugin.store.header.publish" />
                        </a>
                    </li>
                ) : (
                    <li>
                        <NavLink
                            onClick={debounce(
                                loginInterceptor(isLoggedIn, globalBase, () => {
                                    history.push(generateRoutePath('/vault'));
                                }),
                                500
                            )}
                            to="vault">
                            <Message code="messages.com.epicgames.plugin.store.header.vault" />
                        </NavLink>
                    </li>
                )}
                <li>
                    <a
                        href={`${supportSiteUrl}?language=${editedLocale}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Message code="messages.com.epicgames.plugin.store.help" />
                    </a>
                </li>
            </ul>
        </nav>
    );
};
HeaderNav.propTypes = {
    categories: PropTypes.array,
    eventSaleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isEventSaleVisible: PropTypes.bool,
    isLauncher: PropTypes.bool,
    locale: PropTypes.string,
    sellerPortalUrl: PropTypes.string,
    supportSiteUrl: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    globalBase: PropTypes.string,
    history: PropTypes.object,
    tagFreeForMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagPermanentlyFree: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagMarketplaceCollection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagIndustries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagArchitecture: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagAutomotive: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagSimulation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagFashion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagFilm: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default connect(mapStateToProps)(withRouter(HeaderNav));
