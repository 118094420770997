import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {getRoutes} from '@epic-core/utils/dist/RouteUtils';
import {getLocale, generateRoutePath} from '@epic-core/common';
import {LauncherIntegrationUtils} from 'epic-launcher-integration/app/scripts';
import getRouteDefinitions from './routes/routes';
import {CustomizedHistory, ResizeObserver} from './utils';
import {ShoppingCartActions, ConfigActions, UserActions} from './actions';
import {PageLayout} from './pages';
import root from 'window-or-global';
import {emitEvent, setDefaultEventCategory} from 'epic-ue-shared';
import {Footer} from 'epic-ue-header-footer/src/components';
import {extractSlugLocale, withLocalization} from '@epic-core/localization';
import {UeThemeProvider} from 'epic-ue-theme';
import {getTheme} from './Theme';
import {LoginValidator} from './components/login/LoginValidator';
import {AppEffects} from './AppEffects';
import {reduxService} from './shared/redux.service';
import {CabinedCheck} from './CabinedCheck';
import {DismissibleBanner, GlobalNavWrapper} from './components';

const mapStateToProps = state => {
    const {
        includeGlobalHeader,
        includeGlobalFooter,
        isShoppingCartEnabled,
        helmetData,
        isLauncher,
        customizedHistory
    } = state.get('config').toJS();
    const {data: assetData, homeData} = state.get('asset').toJS();
    const {isLoggedIn} = state.get('user').toJS();
    const globalBase = state.get('config').get('globalBase');
    const fullState = state.toJS();
    return {
        localizationState: state.get('localization'),
        includeGlobalHeader,
        includeGlobalFooter,
        isShoppingCartEnabled,
        isLoggedIn,
        helmetData,
        isLauncher,
        customizedHistory,
        globalBase,
        assetData,
        homeData,
        fullState
    };
};
const mapDispatchToProps = dispatch => {
    return {
        listOffers: () => {
            dispatch(ShoppingCartActions.getOffers());
        },
        getLauncherOptions: model => {
            dispatch(ConfigActions.getLauncherOptions(model));
        },
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        },
        handleAddToCart: model => {
            dispatch(ShoppingCartActions.addOffer(model));
        },
        getSso: () => {
            dispatch(UserActions.getSso());
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class App extends React.Component {
    componentDidMount() {
        LauncherIntegrationUtils.initWebIntegration();
        const {
            isLoggedIn,
            listOffers,
            isLauncher,
            getLauncherOptions,
            getSso,
            isShoppingCartEnabled,
            history
        } = this.props;
        if (isLoggedIn) {
            listOffers();
            getSso();
        }
        if (isLauncher) {
            getLauncherOptions({isShoppingCartEnabled, isLoggedIn});
        }
        this.unlisten = history.listen(this.setPageLocation);
        this.setPageLocation(history.location);
        this.sendAnalytics();
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
        if (ResizeObserver()) {
            ResizeObserver().disconnect();
        }
    }

    componentDidCatch(error, info) {
        console.log('componentDidCatch', info);
        console.error('Entry componentDidCatch() caught an error');
        console.error(error);

        emitEvent({
            eventAction: 'client.error',
            eventLabel: error
        });

        setTimeout(() => {
            root.location.href = `/marketplace/${getLocale()}/error?e=entrydidcatch`;
        }, 500);
    }

    componentDidUpdate() {
        //send any data over to the shared service side
        reduxService.updateReduxStore(
            Object.assign(this.props.fullState, {handleAddToCart: this.props.handleAddToCart})
        );
    }

    setPageLocation = location => {
        let {customizedHistory} = this.props;
        const {setConfig} = this.props;
        if (!customizedHistory) {
            const defaultLocation = generateRoutePath('store');
            customizedHistory = new CustomizedHistory(defaultLocation);
        }
        customizedHistory.push(location);
        setConfig({
            customizedHistory
        });
    };

    sendAnalytics() {
        const props = this.props;
        const location = props.location || {};
        const {pageSlug} = extractSlugLocale(location.pathname);
        const nav = root._epicGamesNav || {};
        const browser = nav.browser || {};
        setDefaultEventCategory('UE_MARKETPLACE');
        emitEvent({
            eventAction: 'client.load',
            interactionType: 'init',
            eventLabel: pageSlug
        });
        if (browser.name) {
            emitEvent({
                eventAction: 'client.browser',
                interactionType: 'init',
                eventLabel: `${browser.name} ${browser.version} | ${browser.osname} ${browser.osversion}`
            });
        }
        root.addEventListener('beforeunload', () => {
            emitEvent({
                eventAction: 'client.unload',
                interactionType: 'exit',
                eventLabel: Date.now() - root.unrealEngine_start
            });
        });
    }

    render() {
        const {
            mobile,
            tablet,
            msie,
            location,
            isShoppingCartEnabled,
            includeGlobalHeader,
            includeGlobalFooter,
            isLoggedIn,
            isLauncher,
            globalBase
        } = this.props;
        const classes = classNames(
            `epic-marketplace store-page-asset ${
                isShoppingCartEnabled ? 'enableShoppingCart' : ''
            } ${isLoggedIn ? '' : 'not-logged-in'}`,
            {
                mobile,
                tablet,
                msie
            }
        );
        const routeDefinitions = getRouteDefinitions();
        const routes = getRoutes(routeDefinitions, location);

        return (
            <div className={`site-content ${isLauncher ? 'launcher-scrollbar' : ''}`}>
                <UeThemeProvider theme={getTheme()}>
                    {includeGlobalHeader && <GlobalNavWrapper />}

                    <LoginValidator
                        isLoggedIn={isLoggedIn}
                        location={location}
                        globalBase={globalBase}
                    />
                    <AppEffects />
                    <CabinedCheck />

                    <DismissibleBanner />
                    <div id="epic-marketplace" className={classes}>
                        <PageLayout>{routes}</PageLayout>
                    </div>

                    {includeGlobalFooter && <Footer {...this.props} />}
                </UeThemeProvider>
            </div>
        );
    }
}
App.propTypes = {
    location: PropTypes.object.isRequired,
    includeGlobalHeader: PropTypes.bool,
    includeGlobalFooter: PropTypes.bool,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    msie: PropTypes.bool,
    isShoppingCartEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    listOffers: PropTypes.func,
    history: PropTypes.object,
    isLauncher: PropTypes.bool,
    getLauncherOptions: PropTypes.func,
    getSso: PropTypes.func,
    setConfig: PropTypes.func,
    customizedHistory: PropTypes.object,
    globalBase: PropTypes.string,
    assetData: PropTypes.object,
    homeData: PropTypes.object,
    fullState: PropTypes.object,
    handleAddToCart: PropTypes.func
};
export default withRouter(withLocalization(App));
