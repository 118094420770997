import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {hasValue, loginInterceptor, getReportHref} from '../../utils';
import {getReviewValidations} from '../../data';
import {ReviewActions, ReviewDialogActions, UploadActions} from '../../actions';
import {replyDefaultCount, REVIEW_TYPES} from '../../constants';
import {Rate, Icon, ReadMoreText, getMessage, Message} from '../index';
import ReplyItem from './ReplyItem';
import ReviewSubTitle from './ReviewSubTitle';
import ReviewHelpful from './ReviewHelpful';
import ReviewImages from './ReviewImages';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const {data = {}} = state
        .get('asset')
        .get('data')
        .toJS();
    const {accountInfo, isLoggedIn, isPostingAllowed} = state.get('user').toJS();
    const isLauncher = state.get('config').get('isLauncher');
    const globalBase = state.get('config').get('globalBase');
    const isImageFeatureEnabled = state.get('config').get('isImageFeatureEnabled');
    const {isLoadingReply, isLoadingReview, activeId, isVoting} = state.get('review').toJS();
    const {seller = {}, id} = data;
    return {
        messages,
        sellerId: seller.owner,
        offerId: id,
        accountInfo,
        isLoggedIn,
        isPostingAllowed,
        isLauncher,
        isLoadingReply,
        isLoadingReview,
        activeId,
        globalBase,
        isImageFeatureEnabled,
        isVoting
    };
};

const mapDispatchToProps = dispatch => ({
    editReview: model => dispatch(ReviewActions.editReview(model)),
    deleteReview: model => dispatch(ReviewActions.deleteReview(model)),
    voteReview: model => dispatch(ReviewActions.voteReview(model)),
    addReply: model => dispatch(ReviewActions.addReply(model)),
    showMoreReplies: model => dispatch(ReviewActions.showMoreReplies(model)),
    getReviewReplies: model => dispatch(ReviewActions.getReviewReplies(model)),
    uploadImages: model => dispatch(UploadActions.uploadQueue(model)),
    setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
    mergeDialogOptions: model => dispatch(ReviewDialogActions.mergeOptions(model)),
    hideDialog: () => dispatch(ReviewDialogActions.hide(true))
});

const ReviewItem = props => {
    const prefixCls = 'review-item';
    const {
        className,
        type,
        data = {},
        messages,
        sellerId,
        accountInfo,
        isLoggedIn,
        isPostingAllowed,
        isLauncher,
        isLoadingReply,
        isLoadingReview,
        activeId,
        editReview,
        deleteReview,
        voteReview,
        addReply,
        getReviewReplies,
        showMoreReplies,
        setDialogOptions,
        hideDialog,
        uploadImages,
        offerId,
        fullScreenDispatch,
        isShowReadMore = true,
        globalBase,
        isVoting,
        isImageFeatureEnabled
    } = props;
    const {
        id,
        isCurrentUser,
        rating,
        title,
        replyNum,
        content,
        images = [],
        isReplyEnabled,
        replies,
        replyPaging,
        publisherReply = {},
        bestReply,
        defaultReply,
        identityId,
        displayed
    } = data;
    const classNames = cn(prefixCls, className);
    const [showReplies, toggleReplies] = useState(false);
    const [hideReview, showReviewAnyway] = useState(!displayed);

    useEffect(() => {
        if (isLoadingReview && activeId === id) {
            toggleReplies(false);
        }
    }, [isLoadingReview]);

    const handleShowReplies = async () => {
        if (!showReplies) {
            await getReviewReplies({topicId: id, count: replyDefaultCount, type, offerId});
        }
        toggleReplies(!showReplies);
    };

    const onReply = () => {
        const options = isPostingAllowed
            ? {
                  isVisible: true,
                  validations: getReviewValidations(messages, true),
                  headerText: `messages.com.epicgames.plugin.store.asset.${type}.reply.header`,
                  saveText: `messages.com.epicgames.plugin.store.asset.${type}.reply.submit`,
                  isDescEnable: true,
                  isDescRequired: true,
                  descPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.reply.desc`,
                  onSave: value => {
                      addReply({topicId: id, ...value, type});
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    const onEdit = () => {
        const contentMaxLength =
            type === REVIEW_TYPES.REVIEW || type === REVIEW_TYPES.QUESTION ? 8000 : 3000;
        const options = isPostingAllowed
            ? {
                  headerText: `messages.com.epicgames.plugin.store.asset.${type}.btn.edit`,
                  saveText: `messages.com.epicgames.plugin.store.asset.${type}.btn.edit`,
                  summaryPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.summary`,
                  descPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.desc`,
                  validations: getReviewValidations(
                      messages,
                      false,
                      contentMaxLength,
                      type === REVIEW_TYPES.REVIEW
                  ),
                  isVisible: true,
                  isSummaryEnable: true,
                  isRatingEnable: type === REVIEW_TYPES.REVIEW,
                  isDescEnable: true,
                  isDescRequired: type !== REVIEW_TYPES.REVIEW,
                  isImageEnable: true,
                  contentMaxLength,
                  defaultValue: data,
                  onSave: value => {
                      const nextData = {
                          ...value,
                          offerId,
                          targetOwner: sellerId,
                          type,
                          topicId: id
                      };
                      if (nextData.images) {
                          uploadImages({
                              type,
                              files: nextData.images,
                              onSuccess: nextImages => {
                                  editReview({...nextData, images: nextImages});
                              }
                          });
                      } else {
                          editReview(nextData);
                      }
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    const onDelete = () => {
        setDialogOptions({
            isVisible: true,
            className: 'narrow',
            headerText: `messages.com.epicgames.plugin.store.asset.${type}.delete.header`,
            contentText: `messages.com.epicgames.plugin.store.asset.${type}.delete.content`,
            saveText: 'messages.com.epicgames.plugin.store.asset.review.dialog.delete',
            isShowCancel: true,
            onSave: () => {
                deleteReview({offerId, topicId: id, type});
            }
        });
    };

    const onVote = (params = {}) => {
        if (!params.id || isVoting) return;
        voteReview({...params, type});
    };

    const renderTools = () => {
        let loadReplyEle = null;
        if (isLoadingReply && activeId === id) {
            loadReplyEle = (
                <li>
                    <div className="loading" />
                </li>
            );
        } else if (
            replyNum > 2 ||
            (replyNum === 1 &&
                !hasValue(bestReply) &&
                !hasValue(publisherReply) &&
                !hasValue(defaultReply)) ||
            (replyNum === 2 &&
                !(hasValue(bestReply) && hasValue(publisherReply) && !publisherReply.isBest))
        ) {
            //hidden when the replyNum = 1 and the reply is publisherReply/bestReply
            let showReplyCode;
            if (showReplies) {
                showReplyCode = 'messages.com.epicgames.plugin.store.asset.replies.hide';
            } else if (replyNum === 1) {
                showReplyCode = 'messages.com.epicgames.plugin.store.asset.replies.show.single';
            } else {
                showReplyCode = 'messages.com.epicgames.plugin.store.asset.replies.show';
            }
            loadReplyEle = (
                <li onClick={handleShowReplies}>
                    <span>{getMessage(messages, showReplyCode, [replyNum])}</span>
                </li>
            );
        }
        return (
            <Fragment>
                {!isCurrentUser && (
                    <li title="Report">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            href={getReportHref(
                                type === REVIEW_TYPES.REVIEW ? 'review' : 'question',
                                id,
                                type === REVIEW_TYPES.REVIEW ? 'uem_review' : 'uem_question'
                            )}
                            target="_blank">
                            <Icon name="flag" />
                        </a>
                    </li>
                )}
                {isCurrentUser && (
                    <li title="Edit" onClick={onEdit}>
                        <Icon name="edit" />
                    </li>
                )}
                {isCurrentUser && (
                    <li title="Delete" onClick={onDelete}>
                        <Icon name="trash" />
                    </li>
                )}
                {isReplyEnabled && (
                    <li title="Reply" onClick={loginInterceptor(isLoggedIn, globalBase, onReply)}>
                        <Icon name="reply" />
                    </li>
                )}
                {loadReplyEle}
            </Fragment>
        );
    };

    const onLoadMoreReplies = () => {
        const {start, count} = replyPaging;
        showMoreReplies({start: start + count, count, topicId: id, type, offerId});
    };

    const getReplyTitle = (fromBest, fromPublisher, isLatest) => {
        if (fromBest) {
            return getMessage(
                messages,
                `messages.com.epicgames.plugin.store.asset.replies${
                    fromPublisher ? '.publisher' : ''
                }.best`
            );
        }
        if (fromPublisher && isLatest) {
            return getMessage(
                messages,
                `messages.com.epicgames.plugin.store.asset.${type}.replies.publisher.latest`
            );
        } else if (fromPublisher && !isLatest) {
            return getMessage(
                messages,
                `messages.com.epicgames.plugin.store.asset.${type}.replies.publisher`
            );
        }
        return null;
    };

    const renderReplies = () => {
        const showReplyList =
            (!isLoadingReply || (isLoadingReply && activeId !== id)) &&
            showReplies &&
            replies &&
            replies.length;
        if (showReplyList) {
            const {start, count, total} = replyPaging;
            const noMoreReplies = start + count >= total;
            return (
                <div className={`${prefixCls}__replies`}>
                    {replies.map((it, idx) => {
                        return (
                            <ReplyItem
                                type={type}
                                key={`reply-item-list-${idx}`}
                                topicIdentityId={identityId}
                                isPublisher={it.identityId === sellerId}
                                data={{
                                    ...it,
                                    title: getReplyTitle(
                                        it.isBest,
                                        it.identityId === sellerId,
                                        hasValue(publisherReply) && it.id === publisherReply.id
                                    )
                                }}
                            />
                        );
                    })}
                    {!noMoreReplies && (
                        <div className={`${prefixCls}__replies__more`}>
                            <span onClick={() => onLoadMoreReplies()}>
                                {getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.message.more'
                                )}
                            </span>
                        </div>
                    )}
                </div>
            );
        }
        const displayReply = [];
        if (hasValue(bestReply)) {
            displayReply.push({
                ...bestReply,
                title: getReplyTitle(true, bestReply.identityId === sellerId)
            });
        }
        if (hasValue(publisherReply) && !publisherReply.isBest) {
            displayReply.push({...publisherReply, title: getReplyTitle(false, true, true)});
        }
        if (hasValue(defaultReply)) {
            displayReply.push(defaultReply);
        }
        if (displayReply.length) {
            return (
                <div className={`${prefixCls}__replies`}>
                    {displayReply
                        .filter(reply => reply.displayed)
                        .map((it, idx) => (
                            <ReplyItem
                                key={`${idx}`}
                                className={`${prefixCls}__best-reply`}
                                type={type}
                                data={it}
                                isPublisher={it.identityId === sellerId}
                                topicIdentityId={identityId}
                            />
                        ))}
                </div>
            );
        }
        return null;
    };

    const handleFullScreen = currentIdx => {
        fullScreenDispatch({
            payload: {
                isVisible: true,
                data: images,
                currentIdx
            }
        });
    };

    const renderContent = () => {
        if (hideReview) {
            const isSelf = accountInfo.id === identityId;
            const message = getMessage(
                messages,
                'messages.com.epicgames.plugin.store.asset.reivew_support_for_help'
            );
            return (
                <Fragment>
                    <div className={`${prefixCls}__title`}>
                        <Message code="messages.com.epicgames.plugin.store.asset.review_on_hide" />
                    </div>
                    {isSelf && (
                        <div
                            className={`${prefixCls}__content self_warning`}
                            dangerouslySetInnerHTML={{__html: message}}
                        />
                    )}
                    <ul className={`${prefixCls}__tools`}>
                        <li className="button-show_anyway" onClick={() => showReviewAnyway(false)}>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.asset.review_show_anyway'
                            )}
                        </li>
                    </ul>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <div className={`${prefixCls}__header`}>
                    <div className={`${prefixCls}__title`}>
                        {hasValue(rating) && (
                            <Rate readonly initialRating={rating} isLauncher={isLauncher} />
                        )}
                        <h4>{title}</h4>
                        <ReviewSubTitle data={data} />
                    </div>
                    <ReviewHelpful data={data} onVote={onVote} type={type} />
                </div>
                {isShowReadMore ? (
                    <ReadMoreText
                        className={`${prefixCls}__content rich-text-content`}
                        text={content}
                    />
                ) : (
                    <div dangerouslySetInnerHTML={{__html: content}} />
                )}
                {isImageFeatureEnabled && images.length > 0 && (
                    <ReviewImages
                        className={`${prefixCls}__images`}
                        images={images}
                        fullScreenDispatch={handleFullScreen}
                    />
                )}
                <ul className={`${prefixCls}__tools`}>{renderTools()}</ul>
                {renderReplies()}
            </Fragment>
        );
    };

    return (
        <div className={classNames}>
            {!!isLoadingReview && activeId === id ? <div className="loading" /> : renderContent()}
        </div>
    );
};

ReviewItem.propTypes = {
    className: PropTypes.string,
    offerId: PropTypes.string,
    type: PropTypes.oneOf(['reviews', 'questions']),
    data: PropTypes.object,
    messages: PropTypes.object,
    sellerId: PropTypes.string,
    isLoadingReply: PropTypes.bool,
    isLoadingReview: PropTypes.bool,
    activeId: PropTypes.string,
    accountInfo: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isPostingAllowed: PropTypes.bool,
    isLauncher: PropTypes.bool,
    editReview: PropTypes.func,
    deleteReview: PropTypes.func,
    voteReview: PropTypes.func,
    addReply: PropTypes.func,
    showMoreReplies: PropTypes.func,
    getReviewReplies: PropTypes.func,
    setDialogOptions: PropTypes.func,
    mergeDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    uploadImages: PropTypes.func,
    fullScreenDispatch: PropTypes.func,
    isShowReadMore: PropTypes.bool,
    globalBase: PropTypes.string,
    isVoting: PropTypes.bool,
    isImageFeatureEnabled: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewItem);
