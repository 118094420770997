import React, {useEffect} from 'react';

import {usePageTitle} from '../../shared/pageTitle.hooks';
import {CouponDetail} from './CouponDetailPage.connected';

interface Props {
    match: {
        params: {
            coupons: string;
        };
    };
}

const CouponDetailPage = (props: Props) => {
    const {coupons} = props.match.params;
    const {setPageTitle} = usePageTitle();

    useEffect(() => {
        setPageTitle('messages.com.epicgames.plugin.store.title.coupons');
        return () => setPageTitle('', true);
    }, []);

    return <CouponDetail eventSlug={coupons} />;
};

export default CouponDetailPage;
