/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, AddToCartBtn} from '../index';
import AddReviewBtn from './AddReviewBtn';
import {WishlistToggle} from '../wishlist/WishlistToggle';
import styled from 'styled-components';
import {isCategoryReviewable} from '../../utils';

const WishlistWrap = styled.span`
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    margin: 0;
    top: 6px;

    button.wishlist-toggle {
        height: 24px;
        width: 30px;
        position: absolute;
        right: 6px;
        border-left: 1px solid #a2681161;
        padding: 0;
        .MuiSvgIcon-root {
            font-size: 18px;
            margin-top: -5px;
        }

        ${p => p.theme.breakpoints.down('xs')} {
            display: none;
        }
    }
`;

const mapStateToProps = state => {
    const {accountInfo = {}, isPostingAllowed} = state.get('user').toJS();
    const {epicSellerOwner = ''} = state.get('config').toJS();
    return {
        accountId: accountInfo.id,
        isPostingAllowed,
        epicSellerOwner
    };
};

const AssetTileDetail = props => {
    const data = props.data || {};
    const {epicSellerOwner} = props;
    const {id, categories = [], owned, customAttributes, seller = {}, reviewed} = data;
    const {isLearnAsset, isCompact, accountId, isPostingAllowed} = props;

    const sellerId = seller.owner;
    const isEpicOwner = epicSellerOwner === sellerId;
    const noReviews = accountId === sellerId || isEpicOwner || !isCategoryReviewable(categories);

    const getActionBtn = () => {
        if ((customAttributes && customAttributes.BuyLink) || reviewed) {
            return null;
        }
        if (owned) {
            if (noReviews) {
                return null;
            }
            return <AddReviewBtn isPostingAllowed={isPostingAllowed} data={{id, sellerId}} />;
        }
        return <AddToCartBtn bindId={id} {...props} />;
    };

    const getWishlistBtn = () => {
        if ((customAttributes && customAttributes.BuyLink) || reviewed) {
            return null;
        }
        if (owned) {
            return null;
        }
        return (
            <WishlistWrap>
                <WishlistToggle offerId={id} />
            </WishlistWrap>
        );
    };

    return (
        <ul className="details">
            {!isCompact && !isLearnAsset && (
                <li className="categories">
                    <ul className="mock-text-ellipsis">
                        <div className="mock-ellipsis-container">
                            {categories &&
                                categories.map(cate => {
                                    if (cate.path !== 'assets') {
                                        return (
                                            <li
                                                key={`mock-ellipsis-item-cat-${cate.name}`}
                                                className="mock-ellipsis-item-helper">
                                                <Link
                                                    className="mock-ellipsis-item-cat"
                                                    to={`content-cat/${cate.path}`}>
                                                    {cate.name}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                        </div>
                    </ul>
                    <ul className="categories-full-view">
                        {categories &&
                            categories.map(cate2 => {
                                if (cate2.path !== 'assets') {
                                    return (
                                        <li key={`mock-ellipsis-item-cat2-${cate2.name}`}>
                                            <Link
                                                className="mock-ellipsis-item-cat"
                                                to={`content-cat/${cate2.path}`}>
                                                {cate2.name}
                                            </Link>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                    </ul>
                </li>
            )}
            {getActionBtn()}
            {getWishlistBtn()}
        </ul>
    );
};

AssetTileDetail.propTypes = {
    isLearnAsset: PropTypes.bool,
    isCompact: PropTypes.bool,
    data: PropTypes.object,
    accountId: PropTypes.string,
    isPostingAllowed: PropTypes.bool,
    epicSellerOwner: PropTypes.string
};
export default connect(mapStateToProps, null)(AssetTileDetail);
