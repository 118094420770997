/**
 * Created by Kaku.Guo on 2018/12/6.
 */
import React, {Fragment} from 'react';
import root from 'window-or-global';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {hasValue, formatLink, getReportHref, loadTalon} from '../../utils';
import {Icon, Message} from '../../components';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';
import {getMessage} from '../../components';
import {AssetActions, PageActions, TraderDialogActions} from '../../actions';

const sellerMapDispatchToProps = dispatch => {
    const actions = mapDispatchToProps(dispatch);
    actions.listAssets = model => {
        dispatch(AssetActions.sellerAssets(model));
    };
    actions.handleChange = (func, model) => {
        dispatch(PageActions[func](model));
    };
    actions.setTraderDialogOptions = model => {
        dispatch(TraderDialogActions.setOptions(model));
    };
    return actions;
};

/* Add meta tag descriptions for specific profiles */
const addMetaDescription = sellerName => {
    switch (sellerName) {
        case 'Epic+Games':
            return 'epic.ue.marketplace.meta.epic_content';
        default:
            return null;
    }
};

@connect(mapStateToProps, sellerMapDispatchToProps)
class SellerAssetsView extends BasePageView {
    constructor(props) {
        const {
            match: {
                params: {sellerName}
            }
        } = props;
        const staticParams = {
            sellerName
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.profile'
            },
            pageTitleCode: 'messages.com.epicgames.plugin.store.title.profile',
            pageTileArgs: [sellerName],
            descriptionCode:
                addMetaDescription(sellerName) || 'epic.ue.marketplace.meta.seller_profile',
            descriptionCodeArgs: [sellerName]
        });
    }

    render() {
        const {data = {}, isLoggedIn, messages, setTraderDialogOptions} = this.props;
        const {sellerProfile = {}} = data;
        const {
            name,
            id,
            isDiscontinued,
            facebook,
            twitter,
            blog,
            otherLink,
            website,
            supportEmail,
            isTrader
        } = sellerProfile;

        if (isTrader && !root.talon) loadTalon();

        const showSellerDetails = isLoggedIn && !isDiscontinued;

        const viewTraderDetails = () => {
            const options = {
                isVisible: true,
                isLoggedIn,
                sellerId: id,
                sellerName: name
            };
            setTraderDialogOptions(options);
        };

        // distinguish whether seller has yet to identify as a verified trader or registered non-trader
        const isTraderOrNonTrader = !(isTrader === null || isTrader === undefined);

        return (
            <div className="asset-details-container offset-top seller-assets overflow-y-visible">
                {this.getHelmetElement()}
                {this.getSortDropdown()}
                {hasValue(sellerProfile) && (
                    <section
                        className={`profile-assets-container ${
                            isDiscontinued ? 'discontinued' : ''
                        }`}>
                        <div className="left-column">
                            <div className="sub-header">
                                <h2>
                                    <span>{name}</span>
                                    {isTrader && (
                                        <a
                                            className="verified-trader-icon"
                                            onClick={viewTraderDetails}
                                            title={getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.verified_trader'
                                            )}>
                                            <span />
                                        </a>
                                    )}
                                    {showSellerDetails &&
                                        (facebook || twitter || blog || otherLink) && (
                                            <span className="social-link">
                                                {facebook && (
                                                    <a
                                                        href={formatLink(facebook)}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <i className="icon-facebook3">&nbsp;</i>
                                                    </a>
                                                )}
                                                {twitter && (
                                                    <a
                                                        href={formatLink(twitter)}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <i className="icon-twitter">&nbsp;</i>
                                                    </a>
                                                )}
                                                {blog && (
                                                    <a
                                                        href={formatLink(blog)}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <i className="icon-read">&nbsp;</i>
                                                    </a>
                                                )}
                                                {otherLink && (
                                                    <a
                                                        href={formatLink(otherLink)}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <i className="icon-link">&nbsp;</i>
                                                    </a>
                                                )}
                                            </span>
                                        )}
                                </h2>
                            </div>
                            <div className="seller-details">
                                {showSellerDetails && (
                                    <>
                                        {website && (
                                            <div className="asset-desc-nvs">
                                                <Message code="messages.com.epicgames.plugin.store.asset.support_website" />
                                                <span>
                                                    <a
                                                        href={formatLink(website)}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {website}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                        {supportEmail && (
                                            <div className="asset-desc-nvs">
                                                <Message code="messages.com.epicgames.plugin.store.asset.support_email" />
                                                <span>
                                                    <a href={`mailto:${supportEmail}`}>
                                                        {supportEmail}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                                {isTraderOrNonTrader ? (
                                    <div className="asset-desc-nvs">
                                        {isTrader ? (
                                            <>
                                                <Message code="messages.com.epicgames.plugin.store.trader.trader_details" />
                                                <span>
                                                    <a
                                                        className="trader-details-btn"
                                                        onClick={viewTraderDetails}>
                                                        <Message code="messages.com.epicgames.plugin.store.trader.view_trader_details" />
                                                    </a>
                                                </span>
                                            </>
                                        ) : (
                                            <div className="non-trader">
                                                <Message code="messages.com.epicgames.plugin.store.trader.non_trader" />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div
                            className={`right-column${
                                showSellerDetails && (website || supportEmail)
                                    ? ' has-seller-details'
                                    : ''
                            } ${isTrader ? 'has-trader-info' : ''}`}>
                            <div className="report-seller">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a
                                    href={getReportHref(undefined, undefined, 'uem_seller_profile')}
                                    target="_blank">
                                    <Icon name="flag" />
                                    <Message code="messages.com.epicgames.plugin.store.asset.report.seller" />
                                </a>
                            </div>
                        </div>
                        <hr />
                    </section>
                )}
                {isDiscontinued ? (
                    <h5 className="seller-discontinued">
                        <Message code="messages.com.epicgames.plugin.store.header.seller_discontinued" />
                    </h5>
                ) : (
                    <Fragment>
                        {this.getFilterPanel()}
                        <div className="category-container">
                            {this.getFilterLink()}
                            {this.getAssetList()}
                        </div>
                        <div className="clearfix" />
                    </Fragment>
                )}
                {this.getPaginator()}
            </div>
        );
    }
}
export default withRouter(SellerAssetsView);
