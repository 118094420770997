import React from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
    margin-left: 1em;
    margin-bottom: 2em;
    padding-left: 1em;
    list-style-type: disc;
`;

interface MarkdownListProps {
    children: React.ReactNode;
}

const MarkdownList: React.FC<MarkdownListProps> = ({children}: MarkdownListProps) => (
    <StyledList>{children}</StyledList>
);

export default MarkdownList;
