import React from 'react';

interface MarkdownListItemProps {
    checked?: boolean;
    tight: boolean;
    ordered: boolean;
    index: number;
    children: React.ReactNode;
}

const MarkdownListItem: React.FC<MarkdownListItemProps> = ({children}: MarkdownListItemProps) => (
    <li>{children}</li>
);

export default MarkdownListItem;
