import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
    display: inline-block;
    color: inherit;
    border-bottom: solid 1px;
    cursor: pointer;
    text-decoration: none;
`;

interface MarkdownLinkProps {
    href: string;
    title: string;
    children: string[];
}

const MarkdownLink: React.FC<MarkdownLinkProps> = ({href, title, children}: MarkdownLinkProps) => {
    const url = href.startsWith('http') ? href : `http://${href}`;
    return (
        <Link rel="noopener noreferrer" target="_blank" aria-label={title || ''} href={url}>
            {children}
        </Link>
    );
};

export default MarkdownLink;
