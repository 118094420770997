import marked from 'marked';
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/lib/react-markdown';
import RemarkBreaks from 'remark-breaks';

import MarkdownHeading from './Makrdown.heading';
import MarkdownParagraph from './Markdown.paragraph';
import MarkdownLink from './Markdown.link';
import MarkdownList from './Markdown.list';
import MarkdownListItem from './Makrdown.item';

const RENDERERS = {
    heading: MarkdownHeading,
    link: MarkdownLink,
    linkReference: MarkdownLink,
    list: MarkdownList,
    listItem: MarkdownListItem,
    paragraph: MarkdownParagraph
};

const DISALLOWED_TYPES: ReactMarkdown.NodeType[] = [
    'blockquote',
    'code',
    'html',
    'image',
    'imageReference',
    'inlineCode',
    'virtualHtml'
];

const StyledReactMarkdown = styled(ReactMarkdown)`
    &&& {
        & > p {
            padding: 0;
            margin: 0;
        }
        strong {
            font-weight: 700;
        }
        hr {
            border-style: solid;
            border-width: 0 0 1px;
            opacity: 0.1;
            margin: 3em 0;
            height: 0.08rem;
        }
    }
`;

interface MarkdownProps {
    source: string;
}

export const Markdown: React.FC<MarkdownProps> = ({source}: MarkdownProps) => (
    <StyledReactMarkdown
        skipHtml
        unwrapDisallowed
        source={source}
        plugins={[[RemarkBreaks]]}
        renderers={RENDERERS}
        disallowedTypes={DISALLOWED_TYPES}
    />
);

const markOpt = {
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: true,
    sanitizer: null,
    mangle: true,
    smartLists: false,
    silent: false,
    highlight: null,
    langPrefix: 'lang-',
    smartypants: false,
    headerPrefix: '',
    xhtml: false
};

const renderer = new marked.Renderer();

export const markdownToHtml = (markdownString: string): string => {
    marked.setOptions(markOpt);
    return marked(markdownString, {renderer});
};
