import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Icon, Message, getMessage } from '../index';
import { loginInterceptor, debounce } from '../../utils';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const isLoggedIn = state.get('user').get('isLoggedIn');
    const globalBase = state.get('config').get('globalBase');
    const { isVoting } = state.get('review').toJS();
    return { isLoggedIn, messages, globalBase, isVoting };
};

const ReviewHelpful = ({ messages, isLoggedIn, globalBase, data = {}, onVote, type, isVoting }) => {
    const { isCurrentUser, helpfulNum = 0, id, alreadyUpvote, votingError = {} } = data;
    if (isCurrentUser && !helpfulNum) {
        return null;
    }
    let messageCode;
    if (!helpfulNum) {
        messageCode = `messages.com.epicgames.plugin.store.asset.${type}.helpful.empty`;
    } else if (helpfulNum === 1) {
        messageCode = `messages.com.epicgames.plugin.store.asset.${type}.helpful.single.message`;
    } else {
        messageCode = `messages.com.epicgames.plugin.store.asset.${type}.helpful.message`;
    }
    const getTooltip = () => {
        let tooltipCode;
        if (alreadyUpvote) {
            tooltipCode = 'messages.com.epicgames.plugin.store.asset.reviews.helpful.upvote.remove';
        } else {
            tooltipCode = 'messages.com.epicgames.plugin.store.asset.reviews.helpful.upvote';
        }
        return getMessage(messages, tooltipCode);
    };

    const classNames = cn(alreadyUpvote && 'disabled', isVoting && 'isVoting');

    return (
        <div className="review-item__helpful">
            { votingError.hasError && <p>{ getMessage(messages, 'errors.com.epicgames.review.vote_failed', [type]) }</p> }
            <Message code={messageCode} args={[helpfulNum]} />
            { !isCurrentUser && (<i title={getTooltip()}>
                <Icon
                    name="thumbs-up"
                    className={classNames}
                    onClick={debounce(loginInterceptor(isLoggedIn, globalBase, () => onVote({ id, isHelpful: !alreadyUpvote })), 500)} />
            </i>)}
        </div>
    );
};

ReviewHelpful.propTypes = {
    messages: PropTypes.object,
    data: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    onVote: PropTypes.func,
    type: PropTypes.string,
    isVoting: PropTypes.bool,
    globalBase: PropTypes.string
};

export default connect(mapStateToProps)(ReviewHelpful);
