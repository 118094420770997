/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {getCategoryFromPath} from '../../utils';
import {getMessage} from '../../components';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

const buildHelmet = (categories, category, messages) => {
    const {name} = categories.find(cat => cat.path === category) || {};
    return {
        title: name || getMessage(messages, 'messages.com.epicgames.plugin.store.title.content_cat')
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class CategoryView extends BasePageView {
    constructor(props) {
        const category = getCategoryFromPath(props.match);
        const staticParams = {
            category
        };
        const {categories, messages} = props;
        const helmetData = buildHelmet(categories, category, messages);
        super(props, staticParams, {
            helmetData,
            descriptionCode: 'epic.ue.marketplace.meta.browse'
        });
    }

    componentDidMount() {
        const staticParams = this.state.staticParams || {};
        const {category} = staticParams;
        this.setPageConfig(category);
        super.componentDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {match, handleChange, categories, messages} = nextProps;
        const staticParams = this.state.staticParams || {};
        const {category: oldCategory} = staticParams;
        const category = getCategoryFromPath(match);
        if (category !== oldCategory) {
            this.setPageConfig(category);
            this.setState({
                staticParams: {category},
                helmet: buildHelmet(categories, category, messages)
            });
            handleChange('redirectChange', {category});
        }
        super.UNSAFE_componentWillReceiveProps(nextProps);
    }

    setPageConfig(category) {
        const {categories} = this.props;
        const {name} = categories.find(cat => cat.path === category) || {};
        this.props.setConfig({
            contentHeader: name || ''
        });
    }
}
export default withRouter(CategoryView);
