/**
 * views/index.js
 */
export {default as ErrorView} from './error/ErrorView';
export {default as PageLayout} from './layout/PageLayout';
export {
    default as BasePageView,
    mapStateToProps,
    mapDispatchToProps
} from './basePage/BasePageView';
export {default as AssetsView} from './assets/AssetsPageView';
export {default as NewContentView} from './newContent/NewContentPageView';
export {default as OnSaleView} from './onSale/OnSalePageView';
export {default as EventSaleView} from './eventSale/EventSalePageView';
export {default as HomePageView} from './homePage/HomePageView';
export {default as CategoryView} from './category/CategoryPageView';
export {default as FeaturedView} from './featured/FeaturedPageView';
export {default as FreeView} from './free/FreePageView';
export {default as AssetDetailView} from './assetDetail/AssetDetailView';
export {default as VaultView} from './vault/VaultView';
export {default as SellerAssetsView} from './sellerAssets/SellerAssetsView';
export {default as WishlistPage} from './wishlist/WishlistPage';
export {default as ErrorPage} from './error/ErrorPage';
export {default as CouponListPage} from './coupons/CouponListPage';
export {default as CouponDetailPage} from './coupons/CouponDetailPage';
