import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {Icon, ReadMoreText, getMessage, Message} from '../index';
import ReviewSubTitle from './ReviewSubTitle';
import ReviewHelpful from './ReviewHelpful';
import {ReviewActions, ReviewDialogActions} from '../../actions';
import {getReviewValidations} from '../../data';
import {REVIEW_TYPES} from '../../constants';
import {getReportHref} from '../../utils';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    const globalBase = state.get('config').get('globalBase');
    return {isLoggedIn, isPostingAllowed, messages, accountInfo, globalBase};
};

const mapDispatchToProps = dispatch => ({
    editReply: model => dispatch(ReviewActions.editReply(model)),
    deleteReply: model => dispatch(ReviewActions.deleteReply(model)),
    setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
    mergeDialogOptions: model => dispatch(ReviewDialogActions.mergeOptions(model)),
    hideDialog: () => dispatch(ReviewDialogActions.hide(true)),
    voteReview: model => dispatch(ReviewActions.voteReview(model)),
    setBestReply: model => dispatch(ReviewActions.setBestReply(model))
});

const ReplyItem = props => {
    const prefixCls = 'review-item';
    const {
        messages,
        className,
        data = {},
        accountInfo,
        isPostingAllowed,
        type,
        setDialogOptions,
        hideDialog,
        editReply,
        deleteReply,
        voteReview,
        setBestReply,
        topicIdentityId,
        isPublisher
    } = props;
    const {id, isCurrentUser, title, content, topicId, isBest, displayed, identityId} = data;

    const classNames = cn(prefixCls, className);

    const [hideReview, showReviewAnyway] = useState(!displayed);

    const onEditReply = () => {
        const options = isPostingAllowed
            ? {
                  isVisible: true,
                  defaultValue: {content},
                  validations: getReviewValidations(messages, true),
                  headerText: 'messages.com.epicgames.plugin.store.asset.replies.edit.header',
                  saveText: 'messages.com.epicgames.plugin.store.asset.replies.edit.submit',
                  isDescEnable: true,
                  isDescRequired: true,
                  descPlaceholder: 'messages.com.epicgames.plugin.store.asset.reviews.reply.desc',
                  onSave: value => {
                      editReply({...value, topicId, type, replyId: id});
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    const onDelete = () => {
        setDialogOptions({
            isVisible: true,
            className: 'narrow',
            headerText: 'messages.com.epicgames.plugin.store.asset.replies.delete.header',
            contentText: 'messages.com.epicgames.plugin.store.asset.replies.delete.content',
            saveText: 'messages.com.epicgames.plugin.store.asset.review.dialog.delete',
            isShowCancel: true,
            onSave: () => {
                deleteReply({replyId: id, topicId, type});
            }
        });
    };

    const onVote = params => {
        voteReview({...params, type: REVIEW_TYPES.REPLY, topicId});
    };

    const renderTools = () => {
        return (
            <Fragment>
                {!isCurrentUser && (
                    <li title="Report">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            href={getReportHref(
                                'reply',
                                id,
                                type === REVIEW_TYPES.REVIEW ? 'uem_review' : 'uem_question'
                            )}
                            target="_blank">
                            <Icon name="flag" />
                        </a>
                    </li>
                )}
                {isCurrentUser && (
                    <li title="Edit" onClick={onEditReply}>
                        <Icon name="edit" />
                    </li>
                )}
                {isCurrentUser && (
                    <li title="Delete" onClick={onDelete}>
                        <Icon name="trash" />
                    </li>
                )}
            </Fragment>
        );
    };

    const onSetBestAnswer = () => {
        const fix = isBest ? '.cancel' : '';
        setDialogOptions({
            isVisible: true,
            className: 'narrow',
            headerText: `messages.com.epicgames.plugin.store.asset.questions.mark${fix}.header`,
            contentText: `messages.com.epicgames.plugin.store.asset.questions.mark${fix}.content`,
            saveText: 'messages.com.epicgames.plugin.store.asset.questions.dialog.confirm',
            onSave: () => {
                setBestReply({replyId: id, topicId, isBest: !isBest, type});
            }
        });
    };

    const renderBestAnswerIcon = () => {
        if (type !== REVIEW_TYPES.QUESTION) {
            return null;
        }
        //if not the best answer, and the current user is not the question owner, return null
        if (!isBest && accountInfo.id !== topicIdentityId) {
            return null;
        }
        const bestAnswerClassNames = cn(
            `${prefixCls}__best`,
            isBest && `${prefixCls}__best--isBest`,
            accountInfo.id !== topicIdentityId && 'disabled'
        );
        return (
            <div className={bestAnswerClassNames} onClick={onSetBestAnswer}>
                <Icon
                    name="check-circle"
                    title={
                        !isBest
                            ? getMessage(
                                  messages,
                                  'messages.com.epicgames.plugin.store.asset.questions.mark.tooltip'
                              )
                            : ''
                    }
                />
            </div>
        );
    };

    if (hideReview) {
        const isSelf = accountInfo.id === identityId;
        const message = getMessage(
            messages,
            'messages.com.epicgames.plugin.store.asset.reivew_support_for_help'
        );
        return (
            <Fragment>
                <div className={classNames}>
                    <div className={`${prefixCls}__title`}>
                        <Message code="messages.com.epicgames.plugin.store.asset.review_on_hide" />
                    </div>
                    {isSelf && (
                        <div
                            className={`${prefixCls}__content self_warning`}
                            dangerouslySetInnerHTML={{__html: message}}
                        />
                    )}
                    <ul className={`${prefixCls}__tools`}>
                        <li className="button-show_anyway" onClick={() => showReviewAnyway(false)}>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.asset.review_show_anyway'
                            )}
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }

    return (
        <div className={classNames}>
            <div className={`${prefixCls}__header`}>
                {renderBestAnswerIcon()}
                <div className={`${prefixCls}__title`}>
                    {title && <h4>{title}</h4>}
                    <ReviewSubTitle isPublisher={isPublisher} data={data} />
                </div>
                {type === 'questions' && (
                    <ReviewHelpful data={data} onVote={onVote} type={REVIEW_TYPES.REPLY} />
                )}
            </div>
            <ReadMoreText className={`${prefixCls}__content rich-text-content`} text={content} />
            <ul className={`${prefixCls}__tools`}>{renderTools()}</ul>
        </div>
    );
};

ReplyItem.propTypes = {
    topicIdentityId: PropTypes.string,
    accountInfo: PropTypes.object,
    messages: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    mergeDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    editReply: PropTypes.func,
    deleteReply: PropTypes.func,
    voteReview: PropTypes.func,
    setBestReply: PropTypes.func,
    isPublisher: PropTypes.bool,
    type: PropTypes.oneOf(['reviews', 'questions']).isRequired,
    globalBase: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyItem);
