import React from 'react';
import {hot} from 'react-hot-loader';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import Message from '../../components/common/message/Message';
import styled from 'styled-components';
import {UeThemeProvider} from 'epic-ue-theme';
import {generateRoutePath} from '@epic-core/common';

const ErrorPageWrapper = styled.div`
    a {
        text-decoration: none;
    }

    .error-box.error-box-500 {
        div {
            top: 1%;
        }
        h2 {
            font-size: 36px;
        }
    }

    #error-page-btn {
        margin-top: 2em;
    }

    .error-box {
        width: 520px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        top: 28%;
        padding: 50px 55px 50px 35px;
        ${p => p.theme.breakpoints.down('sm')} {
            width: 300px;
            background-size: contain;
            padding: 100px 20px 40px;
            top: 5%;
        }

        .error-msg {
            padding: 2em 0 1em;
        }

        div {
            position: relative;
            top: 2%;
            text-align: center;

            h1 {
                font-size: 100px;
                color: white;
                font-weight: bold;
                border-right: 3px solid white;
                display: inline-block;
                line-height: 70px;
                padding-right: 10px;
                margin: 0;
                margin-bottom: 20px;
                font-family: Brutal, Brutal_Medium, Arial, sans-serif;
                text-align: left;
                ${p => p.theme.breakpoints.down('sm')} {
                    text-align: center;
                    border: none;
                    padding: 0;
                    margin-bottom: 50px;
                }
            }

            h2 {
                margin-left: 10px;
                margin-bottom: 5px;
                font-size: 27px;
                color: white;
                font-weight: bold;
                display: inline-block;
                line-height: 40px;
                word-wrap: break-word;
                text-transform: uppercase;
                font-family: Brutal, Brutal_Medium, Arial, sans-serif;
                text-align: left;
                ${p => p.theme.breakpoints.down('sm')} {
                    margin: 5px 0 5px 0;
                    width: 100%;
                    display: block;
                    text-align: center;
                    br {
                        content: ' ';
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
            h3 {
                font-size: 20px;
                color: white;
                font-weight: bold;
                line-height: 1;
                margin: 0;
                margin-bottom: 5px;
                text-align: left;
                ${p => p.theme.breakpoints.down('sm')} {
                    text-align: center;
                    margin-bottom: 1em;
                }
            }

            p {
                color: white;
                font-size: 17px;
                margin: 0;
                padding: 0;
                text-align: left;
                line-height: 1.4em;
                ${p => p.theme.breakpoints.down('sm')} {
                    text-align: center;
                    line-height: 1.8em;
                }
            }
        }
        #error-page-btn {
            border: none;
        }
    }
`;

interface Properties {
    staticContext: any;
    match: RouteComponentProps;
}

const ErrorPage = ({match = {}, staticContext}: Properties) => {
    const {params = {}} = match;
    const url = match.url;
    let error = params.error;
    if (!error) {
        if (url.indexOf('error-500') !== -1 || url.indexOf('unexpectedError') !== -1) {
            error = 500;
        } else if (url.indexOf('error-404') !== -1) {
            error = 404;
        } else {
            error = 404;
        }
    }

    if (staticContext) {
        staticContext.status = error;
    }

    const title = <Message code={`epic.error.${error}.title1`} />;
    const subTitle = <Message code={`epic.error.${error}.title2`} />;
    const message = <Message code={`epic.error.${error}.msg`} />;
    const button = <Message code="epic.error.404.home" />;

    return (
        <UeThemeProvider>
            <ErrorPageWrapper>
                <div id="ErrorPage" className="error-box">
                    <div>
                        <h1>{error}</h1>
                        <h2>
                            {title}
                            <br />
                            {subTitle}
                        </h2>
                        <div className="error-msg">
                            {error === 500 ? (
                                <div>
                                    <h3>
                                        <Message code="epic.error.500.msg1" />
                                    </h3>
                                    <p>
                                        <Message code="epic.error.500.msg2" />
                                    </p>
                                </div>
                            ) : (
                                <p>{message}</p>
                            )}
                        </div>

                        <Link to={generateRoutePath('/store')} type="primary" id="error-page-btn">
                            {button}
                        </Link>
                    </div>
                </div>
            </ErrorPageWrapper>
        </UeThemeProvider>
    );
};

export default hot(module)(withRouter(ErrorPage));
