import React from 'react';
import HomePage from './HomePage.connected';
import {AssetActions} from '../../actions';

export default class HomePageView extends React.Component {
    static async getInitialProps(match, {dataPreload, parsedUrl, store}, token) {
        store.dispatch(AssetActions.getHomeData({token}));
    }

    render() {
        return <HomePage {...this.props} />;
    }
}
