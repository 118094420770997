/**
 * Created by Kaku.Guo on 2018/11/23.
 */
import React, {useState, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Switch, Route} from 'react-router';
import AssetDetailGallery from './gallery/AssetDetailGallery';
import AssetDetailContent from './content/AssetDetailContent';
import AssetDetailDesc from './description/AssetDetailDesc';
import AssetQuestions from './question/AssetQuestions';
import AssetReviews from './reviews/AssetReviews';
import {
    Icon,
    TabList,
    Tab,
    ReviewSummaryReducer,
    ReviewSummaryDialog,
    Beta,
    getMessage,
    ContextWrapper
} from '../index';
import {getReviewFromQuery} from '../review/reviewUtils';
import {ReviewActions, TraderDialogActions} from '../../actions';
import {Redirect} from 'react-router';
import {isCategoryReviewable, isCategoryQuestionable, getReportHref} from '../../utils';

const getTabs = (noReviews, noQuestions, isEpicOwner = false, messages) => {
    const initTabs = [
        {
            id: 'tab-desc',
            title: getMessage(messages, 'messages.com.epicgames.plugin.store.asset.description'),
            panelId: 'panel-desc',
            anchor: ''
        }
    ];
    if (isEpicOwner) {
        return initTabs;
    }
    if (!noReviews) {
        initTabs.push({
            id: 'tab-reviews',
            title: getMessage(messages, 'messages.com.epicgames.plugin.store.asset.reviews'),
            panelId: 'panel-reviews',
            anchor: 'reviews',
            beta: false
        });
    }
    if (!noQuestions) {
        initTabs.push({
            id: 'tab-questions',
            title: getMessage(messages, 'messages.com.epicgames.plugin.store.asset.questions'),
            panelId: 'panel-questions',
            anchor: 'questions',
            beta: false
        });
    }
    return initTabs;
};

const mapDispatchToProps = dispatch => ({
    getReviewDetail: model => dispatch(ReviewActions.getReviewDetail(model)),
    setTraderDialogOptions: model => dispatch(TraderDialogActions.setOptions(model))
});

const mapStateToProps = state => {
    const {epicSellerOwner = ''} = state.get('config').toJS();
    const {isLoggedIn} = state.get('user').toJS();
    return {epicSellerOwner, isLoggedIn};
};

function AssetDetail(props) {
    const [activeTab, setActiveTab] = useState('tab-desc');
    const {
        data = {},
        history,
        location,
        messages,
        match,
        getReviewDetail,
        isLearnAsset,
        epicSellerOwner
    } = props;
    const {owner} = data.seller || {};
    const isEpicOwner = epicSellerOwner === owner;
    const {id, customAttributes = {}} = data;
    let buyLink = customAttributes.BuyLink;
    buyLink = buyLink ? buyLink.value : null;
    const {categories = []} = data;
    const noReviews = isEpicOwner || !isCategoryReviewable(categories) || !!buyLink;
    const noQuestions = !isCategoryQuestionable(categories);
    const tabs = getTabs(noReviews, noQuestions, isEpicOwner, messages);

    useEffect(() => {
        const pathSnippets = location.pathname.split('/').filter(i => i);
        const lastPath = pathSnippets[pathSnippets.length - 1];
        const nextActiveTab = tabs.find(item => item.anchor === lastPath) || tabs[0];
        setActiveTab(nextActiveTab.id);
    }, [location.pathname]);

    const [reviewSummaryOptions, reviewSummaryDispatch] = useReducer(ReviewSummaryReducer);

    useEffect(() => {
        const reviewInQuery = getReviewFromQuery(location.search);
        if (id && reviewInQuery) {
            reviewSummaryDispatch({
                payload: {
                    isVisible: true,
                    type: reviewInQuery.type
                }
            });
            getReviewDetail({
                offerId: id,
                ...reviewInQuery
            });
        }
    }, []);

    const [preventScroll, setPreventScroll] = useState(false);
    const switchTab = (tab, needScroll) => {
        const anchor = tab.anchor;
        setPreventScroll(!needScroll);
        history.replace(`${match.url}${anchor ? `/${anchor}` : ''}${location.search}`);
    };

    return (
        <div className={`asset-details-container offset-top ${isLearnAsset ? 'learn' : ''}`}>
            <ContextWrapper styleClass="asset-details">
                <AssetDetailGallery {...props} />
                <AssetDetailContent {...props} />
            </ContextWrapper>
            <section className="asset-content-container" id="review-content">
                <div className="asset-content container-fluid">
                    <TabList>
                        {tabs.map(tab => {
                            return tab.ghost ? null : (
                                <Tab
                                    activeTabId={activeTab}
                                    id={tab.id}
                                    key={tab.id}
                                    onClick={() => switchTab(tab)}
                                    panelId={tab.panelId}
                                    className={tab.class}>
                                    {tab.title}
                                    {tab.beta && <Beta />}
                                </Tab>
                            );
                        })}
                        <div className="report-product">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                href={getReportHref(undefined, undefined, 'uem_product_listing')}
                                target="_blank">
                                <Icon name="flag" />
                                {getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.asset.reviews.report.product'
                                )}
                            </a>
                        </div>
                    </TabList>
                    <Switch>
                        <Route
                            exact
                            path={match.url}
                            render={() => <AssetDetailDesc {...props} />}
                        />
                        <Route
                            exact
                            path={`${match.url}/reviews`}
                            render={() => {
                                if (isEpicOwner) {
                                    return <AssetDetailDesc {...props} />;
                                }
                                return <AssetReviews preventScroll={preventScroll} />;
                            }}
                        />
                        <Route
                            path={`${match.url}/reviews/`}
                            render={() => {
                                return <Redirect to={`${match.url}/reviews`} />;
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}/questions`}
                            render={() => {
                                if (isEpicOwner) {
                                    return <AssetDetailDesc {...props} />;
                                }
                                return <AssetQuestions preventScroll={preventScroll} />;
                            }}
                        />
                        <Route
                            path={`${match.url}/questions/`}
                            render={() => {
                                return <Redirect to={`${match.url}/questions`} />;
                            }}
                        />
                        <Route
                            path={`${match.url}/*`}
                            render={() => {
                                return <Redirect to={match.url} />;
                            }}
                        />
                    </Switch>
                </div>
                <ReviewSummaryDialog
                    options={reviewSummaryOptions}
                    stateDispatch={reviewSummaryDispatch}
                />
            </section>
        </div>
    );
}
AssetDetail.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    data: PropTypes.object,
    messages: PropTypes.object,
    getReviewDetail: PropTypes.func,
    isLearnAsset: PropTypes.bool,
    epicSellerOwner: PropTypes.string
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetDetail));
