import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {PageHeader, Message, ReviewDialog, TraderDialog, Banner} from '../../components';
import {ErrorView} from '../../pages';
import {ErrorActions} from '../../actions';
import {Redirect} from 'react-router';
import {generateRoutePath} from '@epic-core/common';
import EulaDialog from '../../components/eula/EulaDialog';
import {UnrealLoading} from 'epic-ue-loading';

const mapStateToProps = state => {
    const {error: pageError} = state.get('asset').toJS();
    const {status: errorStatus} = state.get('error').toJS();
    const {isLoggedIn, accountInfo} = state.get('user').toJS();

    return {pageError, errorStatus, isLoggedIn, accountInfo};
};

const mapDispatchToProps = dispatch => {
    return {
        resetError: () => {
            dispatch(ErrorActions.resetError());
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class PageLayout extends Component {
    getErrorMessage = error => {
        if (error && error.errorCode) {
            return (
                <p className="error-message-container">
                    <i className="esport-icon-caution" />
                    <Message code={error.errorCode} />
                </p>
            );
        }
        return '';
    };

    getPageContent = () => {
        const {children, pageError, errorStatus} = this.props;
        if (errorStatus) {
            return <ErrorView />;
        }
        return (
            <div>
                {this.getErrorMessage(pageError)}
                {children}
            </div>
        );
    };

    render() {
        const {isLoggedIn, accountInfo} = this.props;
        const location = this.props.location || {};
        const pathname = location.pathname || '';
        let isLearnPath;
        // hotfix EGS-39903 & UEMP-300, PageHeader disappear in marketplace category dropdown for learn category
        if (pathname.indexOf('/content-cat/learn') > -1) {
            isLearnPath = false;
        } else {
            isLearnPath = pathname.indexOf('/learn') > -1;
        }
        const {errorStatus} = this.props;

        if (errorStatus === 404) {
            return <Redirect to={generateRoutePath(`/error-404?e=${pathname}`)} />;
        }
        if (isLoggedIn && !accountInfo?.id) {
            return (
                <div className="page-body">
                    <UnrealLoading fillViewHeight={false} />
                </div>
            );
        }
        return (
            <div className="page-body">
                <Banner />
                <PageHeader isLearnPath={isLearnPath} />
                {this.getPageContent()}
                <ReviewDialog />
                <TraderDialog />
                <EulaDialog />
            </div>
        );
    }
}

PageLayout.propTypes = {
    children: PropTypes.any,
    isLoggedIn: PropTypes.bool,
    accountInfo: PropTypes.object,
    pageError: PropTypes.object,
    location: PropTypes.object,
    errorStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    resetError: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(PageLayout);
