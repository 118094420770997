import React, {useCallback} from 'react';
import styled from 'styled-components';
import {CatalogOffer} from 'epic-graphql-types';
import {WishlistToggle} from './WishlistToggle';
import {WishlistPrice} from './WishlistPrice';
import {WishlistAddToCartBtn} from './WishlistAddToCartBtn';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {emitEvent} from 'epic-ue-shared';

const Content = styled.div`
    height: 80px;
    padding: 0 1em 0 2em;

    ${p => p.theme.breakpoints.down('sm')} {
        padding-right: 0.25em;
        padding-left: 1em;
    }

    h2.offer-title {
        transition: color 0.2s ease-out;
        font-size: 18px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: #c7c7c7;
        text-align: left;

        ${p => p.theme.breakpoints.down('sm')} {
            font-size: 15px;
            line-height: 1em;
            text-align: left;
        }

        ${p => p.theme.breakpoints.down('xs')} {
            font-size: 13px;
        }
    }
`;

const WishlistItemWrap = styled.div`
    padding: 0;
    background-color: #212121;
    transition: background-color 0.2s ease-out;
    margin: 0.25em 0;
    width: 100%;
    display: flex;

    &:hover {
        background-color: #191919;
        button {
            background-color: #191919;
        }
    }

    .wishlist-clickable {
        display: flex;

        ${Content} {
            h2 {
                color: #c7c7c7;
            }
        }

        &:hover {
            ${Content} {
                h2 {
                    color: white !important;
                }
            }
        }
    }
`;

const Image = styled.div<{src: string}>`
    background: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url(${p => p.src});
    width: 80px;
    min-width: 80px;
    height: 80px;
`;

const Toolbar = styled.div`
    height: 80px;
    margin-left: auto;
    display: flex;

    button {
        transition: background-color 0.2s ease-out;
        background-color: #212121;
        height: 100%;
        width: 100px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        ${p => p.theme.breakpoints.down('sm')} {
            width: 55px;
        }

        ${p => p.theme.breakpoints.down('xs')} {
            width: 45px;
        }
    }

    .price-wrap {
        height: 30px;
        padding: 0 2em;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        ${p => p.theme.breakpoints.down('sm')} {
            padding: 0 0.5em;
        }
    }
`;

const THUMBNAIL = 'Thumbnail';

const getImage = keyImages => {
    let thumbnailImageSrc;

    //get first thumbnail
    for (let i = 0; i < keyImages.length; i++) {
        const image = keyImages[i];
        if (image.type === THUMBNAIL && image.url) {
            thumbnailImageSrc = image.url;
            break;
        }
    }

    //if no thumb then get first image
    if (!thumbnailImageSrc) {
        for (let i = 0; i < keyImages.length; i++) {
            const image = keyImages[i];
            if (image.url) {
                thumbnailImageSrc = image.url;
                break;
            }
        }
    }

    return thumbnailImageSrc;
};

export const WishlistItem = (offer: CatalogOffer) => {
    const {title, id, keyImages} = offer;
    const image = getImage(keyImages);

    const price = offer.price || {};
    const totalPrice = price.totalPrice || {};
    const currencyCode = totalPrice.currencyCode;
    const fmtPrice = totalPrice.fmtPrice || {};
    const originalPriceStr = fmtPrice.originalPrice;
    const discountPriceStr = fmtPrice.discountPrice;

    const hasPrice = Boolean(originalPriceStr && originalPriceStr.length);

    const isSale = Boolean(
        hasPrice &&
            discountPriceStr &&
            discountPriceStr.length &&
            originalPriceStr !== discountPriceStr
    );

    const onClick = useCallback(() => {
        emitEvent({
            eventAction: 'wishlist.item.load',
            interactionType: 'interaction',
            eventValue: id
        });
    }, []);

    return (
        <WishlistItemWrap className="wishlist-item" key={`wishlist-item-${id}`}>
            <Link
                to={generateRoutePath(`/product/${id}`)}
                onClick={onClick}
                className="wishlist-clickable"
                title={title}>
                <Image src={image}></Image>
                <Content>
                    <h2 className="offer-title">{title}</h2>
                </Content>
            </Link>
            <Toolbar>
                <WishlistPrice
                    priceStr={originalPriceStr}
                    discountStr={discountPriceStr}
                    hasDiscount={isSale}
                    currencyCode={currencyCode}
                />
                <WishlistAddToCartBtn key={`wishlist-cart-btn-${id}`} offerId={id} />
                <WishlistToggle key={`wishlist-toggle-${id}`} offerId={id} />
            </Toolbar>
        </WishlistItemWrap>
    );
};
