import React from 'react';
import styled from 'styled-components';
import root from 'window-or-global';
import {useLocalization} from '@epic-core/localization';
import {UnrealLoading} from 'epic-ue-loading';
import {CouponListItem} from '../../components/coupons/CouponListItem';
import {useCoupons} from '../../shared/coupons/coupon.hooks';

const CouponList = styled.div`
    margin: 2em auto 4em;
    > div:last-child {
        border-bottom: solid 0px;
    }
`;

const EmptyData = styled.div`
    margin: 2em auto 4em;
    color: #ccc;
    font-size: 16px;
    text-align: center;
`;

export const CouponListPageWrapper = ({identityId}: {identityId: string}) => {
    const {couponsStore, loading, loaded} = useCoupons(identityId);
    const isServerSide = root.__server_side_render;
    const getMessage = useLocalization();
    if (isServerSide || loading) {
        return (
            <CouponList className="container">
                <UnrealLoading fillViewHeight={false} />
            </CouponList>
        );
    }
    const notEmpty = couponsStore.length && loaded;

    return (
        <CouponList className="container">
            {notEmpty ? (
                couponsStore.map(coupon => <CouponListItem key={coupon.code} {...coupon} />)
            ) : (
                <EmptyData>{getMessage('epic.marketplace.coupons.empty')}</EmptyData>
            )}
        </CouponList>
    );
};

export default CouponListPageWrapper;
