/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {withRouter} from 'react-router';
import moment from 'moment';
import {formatDate} from '../../utils';
import {connect} from 'react-redux';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

@connect(mapStateToProps, mapDispatchToProps)
class NewContentView extends BasePageView {
    constructor(props) {
        const staticParams = {
            effectiveDate: `[${formatDate(moment.utc().subtract(7, 'days'))},${formatDate(
                moment.utc()
            )}]`
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.new_content'
            },
            pageTitleCode: 'messages.com.epicgames.plugin.store.header.new_content',
            descriptionCode: 'epic.ue.marketplace.meta.new'
        });
    }
}
export default withRouter(NewContentView);
