import React, {Component} from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import root from 'window-or-global';
import {withRouter} from 'react-router';
import pathToRegexp from 'path-to-regexp';
import {extractSlugLocale} from '@epic-core/localization';
import {emitEvent} from 'epic-ue-shared';

// todo matchGroup ? other TAB in other page
const getMatchMap = () => [
    `${root.appContext}/:local/item/:id/:reviewTab?`,
    `${root.appContext}/:local/product/:slug/:reviewTab?`
];

const getMatchPath = path => {
    let result = null;
    getMatchMap().some(item => {
        // eslint-disable-line
        const reg = pathToRegexp(item);
        const match = reg.exec(path);
        if (match) {
            result = match;
            return true;
        }
    });
    return result;
};

const shouldScrollToTop = (prevPath, currentPath) => {
    const prevMatch = getMatchPath(prevPath);
    const currentMatch = getMatchPath(currentPath);
    if (prevMatch && currentMatch) {
        const prevId = prevMatch[2];
        const currentId = currentMatch[2];
        if (prevId === currentId) {
            return false;
        }
        return true;
    }
    return true;
};

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        const prevPath = prevProps.location.pathname;
        const currentPath = this.props.location.pathname;
        if (currentPath !== prevPath) {
            if (shouldScrollToTop(prevPath, currentPath)) {
                root.scrollTo(0, 0);
            }

            const {pageSlug} = extractSlugLocale(currentPath);
            emitEvent({
                eventAction: 'client.route',
                interactionType: 'route',
                eventLabel: pageSlug
            });
        }
    }

    render() {
        return this.props.children;
    }
}

ScrollToTop.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object
};

export default withRouter(ScrollToTop);
