import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import {useLocalization} from '@epic-core/localization';
import {useShoppingCart} from '../../shared/redux.hooks';
import {useAddToCart} from '../../shared/wishlist.hooks';

interface Properties {
    offerId: string;
}

const WishlistAddToCartBtnWrap = styled.div`
    position: relative;
    cursor: pointer;
    transition: 0.2s ease-out;
    height: 100%;
    width: 100px;
    ${p => p.theme.breakpoints.down('sm')} {
        width: 55px;
    }
    ${p => p.theme.breakpoints.down('xs')} {
        width: 45px;
    }
    &:hover {
        background-color: #ffcd43;
        color: #000;
    }
    i {
        top: 36%;
        position: relative;
        font-size: 20px;
        height: 100%;
        width: 100%;
        text-align: center;
    }
    &.disable {
        visibility: hidden;
    }
`;

export const WishlistAddToCartBtn = ({offerId}: Properties) => {
    const {addToCart} = useAddToCart(offerId);
    const getMessage = useLocalization();
    const {isAddingOffer, offerIds: offersInCart} = useShoppingCart();

    const disable = offersInCart.includes(offerId) || isAddingOffer;
    return (
        <Tooltip
            title={getMessage('messages.com.epicgames.plugin.store.message.cart.add_to_cart')}
            arrow
            placement="top">
            <WishlistAddToCartBtnWrap
                className={`add-to-cart-wrap ${disable ? 'disable' : ''}`}
                onClick={addToCart}>
                <span className="icon">
                    <i className="wishlist-shopping-cart-icon fa fa-cart-plus" />
                </span>
            </WishlistAddToCartBtnWrap>
        </Tooltip>
    );
};
