/**
 * Created by Kaku.Guo on 2018/12/6.
 */
import React, {Component, Fragment} from 'react';
import root from 'window-or-global';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {UnrealLoading} from 'epic-ue-loading';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {AssetList, RelatedAssetList, getMessage, Paginator} from '../../components';
import {AssetActions, CmsActions, ConfigActions, ShoppingCartActions} from '../../actions';
import {redirectLogin, combinePageTitle} from '../../utils';
import {getHelmetMetaTags} from '../../utils/metaTagUtils';

const mapStateToProps = state => {
    const {isLoading: isLoadingCms, cmsPageData} = state.get('cms').toJS();
    const {isLoading: isLoadingAsset, data} = state.get('asset').toJS();
    const {isLoggedIn} = state.get('user').toJS();
    const {globalBase, isLauncher} = state.get('config').toJS();
    const {isAddingOffer, currentOffer, offerIds} = state.get('shoppingCart').toJS();
    const messages = state
        .get('localization')
        .get('messageProperties')
        .toJS();
    return {
        isLoading: isLoadingAsset || isLoadingCms,
        data,
        isLoggedIn,
        globalBase,
        cmsPageData,
        messages,
        isLauncher,
        isAddingOffer,
        currentOffer: currentOffer ? currentOffer.offerId : '',
        offersInCart: offerIds
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        },
        vaultAssets: model => {
            dispatch(AssetActions.vaultAssets(model));
        },
        handleAddToCart: model => {
            dispatch(ShoppingCartActions.addOffer(model));
        },
        getCmsPageData: slug => {
            dispatch(CmsActions.getCmsPageData(slug));
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class VaultView extends Component {
    constructor(props) {
        super(props);
        if (!props.isLoggedIn) {
            //redirect to login page
            redirectLogin(props.globalBase);
        }
        this.state = {
            start: 0,
            count: 20
        };
    }

    componentDidMount() {
        const {
            getCmsPageData,
            isLoggedIn,
            vaultAssets,
            setConfig,
            location: {pathname}
        } = this.props;
        if (!isLoggedIn) {
            return;
        }
        setConfig({
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.vault'
            }
        });
        vaultAssets(this.buildParams(this.state));
        const isServerSide = root.__server_side_render;
        if (!isServerSide) {
            getCmsPageData(pathname);
        }
    }

    getPaginator = () => {
        const {data} = this.props;
        const {paging = {}} = data;
        const {start, count} = paging;
        const total = data && data.paging ? data.paging.total : 0;
        const pageCount = Math.ceil(total / count);

        return (
            <Paginator
                defaultPageSize={20}
                pageSizeOptions={['8', '20', '60', '100']}
                pageCount={pageCount}
                current={start === 0 ? 1 : Math.floor(start / count) + 1}
                handleChange={this.handlePageChange}
                total={total}
                pageSize={count || 20}
            />
        );
    };

    handlePageChange = params => {
        const newParams = Object.assign({}, this.state, params);
        this.props.vaultAssets(this.buildParams(newParams));
        this.setState(newParams);
    };

    buildParams = params => {
        const {start, count} = params;
        return {start, count};
    };

    getHelmetElement() {
        const {cmsPageData, isLoading, locale, messages} = this.props;
        if (cmsPageData && Object.keys(cmsPageData).length) {
            return getHelmetForMetaTags(cmsPageData, 'UE Marketplace');
        } else if (!cmsPageData && !isLoading) {
            // if no cmsPageData, fallback to in-app helmet data
            const pageTitle = combinePageTitle(
                messages,
                getMessage,
                'messages.com.epicgames.plugin.store.title.vault'
            );
            const helmetTags = getHelmetMetaTags({
                title: pageTitle,
                description: getMessage(messages, 'epic.ue.marketplace.meta.vault'),
                locale
            });

            return <Helmet {...helmetTags} />;
        }
        return null;
    }

    render() {
        const {
            isLoading,
            data = {},
            messages,
            isAddingOffer,
            currentOffer,
            offersInCart,
            handleAddToCart,
            isLauncher
        } = this.props;

        if (isLoading) {
            return <UnrealLoading fillViewHeight={false} />;
        }
        const {recommendOffers, ...assetData} = data;

        return (
            <Fragment>
                {this.getHelmetElement()}
                <div className="category-container vault-page">
                    <AssetList
                        data={assetData}
                        handleChange={this.handlePageChange}
                        messages={messages}
                        isVaultPage
                    />
                    {this.getPaginator()}
                </div>
                <RelatedAssetList
                    header={getMessage(
                        messages,
                        'messages.com.epicgames.plugin.store.recommend_assets'
                    )}
                    data={recommendOffers}
                    messages={messages}
                    isAddingOffer={isAddingOffer}
                    currentOffer={currentOffer}
                    offersInCart={offersInCart}
                    handleAddToCart={handleAddToCart}
                    isLauncher={isLauncher}
                />
            </Fragment>
        );
    }
}
VaultView.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    vaultAssets: PropTypes.func,
    setConfig: PropTypes.func,
    cmsPageData: PropTypes.object,
    getCmsPageData: PropTypes.func,
    messages: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    globalBase: PropTypes.string,
    isAddingOffer: PropTypes.bool,
    handleAddToCart: PropTypes.func,
    offersInCart: PropTypes.array,
    currentOffer: PropTypes.string,
    isLauncher: PropTypes.bool,
    locale: PropTypes.string,
    location: PropTypes.object
};
export default VaultView;
