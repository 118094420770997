/**
 * Created by Kaku.Guo on 2018/11/12.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link, Message, AddToCartBtn} from '../index';
import {getAssetPath} from '../../utils';
import {getOptImage} from 'epic-ue-shared';
import {WishlistToggle} from '../wishlist/WishlistToggle';
import styled from 'styled-components';

const WishlistWrap = styled.span`
    display: inline-block;
    float: right;
    height: 45px;
    margin: 0;

    button.wishlist-toggle {
        height: 45px;
        width: 35px;
        border-left: 1px solid #a2681161;
        .MuiSvgIcon-root {
            font-size: 18px;
            margin-top: -5px;
        }
    }
`;

const FeaturedContent = props => {
    const {data} = props;
    const getInfo = it => {
        if (it.customAttributes && it.customAttributes.BuyLink) {
            return (
                <span className="price">
                    <Message
                        className="asset-price"
                        code="messages.com.epicgames.plugin.store.asset.vendor_provide"
                    />
                </span>
            );
        } else if (it.owned) {
            return (
                <span className="price owned">
                    <Message
                        className="asset-price"
                        code="messages.com.epicgames.plugin.store.asset.owned"
                    />
                </span>
            );
        } else if (it.free) {
            return (
                <span className="price">
                    <Message code="messages.com.epicgames.plugin.store.asset.free" />
                </span>
            );
        }
        return (
            <span className="price">
                {it.discounted ? (
                    <Fragment>
                        <span className="asset-discount-price">{it.price}</span>&nbsp;
                        {it.discountPrice}
                    </Fragment>
                ) : (
                    it.price
                )}
            </span>
        );
    };

    const DEFAULT_ASSET_IMAGE = getAssetPath('logo-epic.svg');
    return (
        <div className="store-featured-items-container">
            {data.map(item => {
                if (item.urlSlug) {
                    const buyLink = item.customAttributes && item.customAttributes.BuyLink;
                    return (
                        <Link key={`featured-${item.urlSlug}`} to={`product/${item.urlSlug}`}>
                            <div
                                className="featured-tile"
                                style={{
                                    background: `url(${getOptImage({
                                        url: item.featured || DEFAULT_ASSET_IMAGE,
                                        width: 600
                                    })}) no-repeat center center`,
                                    backgroundSize: 'cover'
                                }}>
                                <div
                                    className={`featured-info ${item.owned ? 'owned' : ''} ${
                                        buyLink ? 'external-asset' : ''
                                    }`}>
                                    <div className="info-wrapper">
                                        <Message
                                            className="featured-text"
                                            code="messages.com.epicgames.plugin.store.asset.featured"
                                        />
                                        <div className="name-price-wrapper">
                                            <h2
                                                className={`name ${
                                                    item.discounted ? 'discounted' : ''
                                                }`}>
                                                {item.title}
                                            </h2>
                                            {getInfo(item)}
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                    {!item.owned && !buyLink && (
                                        <div className="add-cart-row">
                                            <WishlistWrap>
                                                <WishlistToggle offerId={item.id} />
                                            </WishlistWrap>
                                            <AddToCartBtn bindId={item.id} {...props} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                    );
                }
                return null;
            })}
        </div>
    );
};
FeaturedContent.propTypes = {
    data: PropTypes.array
};
export default FeaturedContent;
