/**
 * Created by Kaku.Guo on 2018/11/23.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Message, ReadMoreText} from '../../index';
import {slateToHtml, isJsonString} from '../../../utils';

const AssetDetailDesc = props => {
    const {data = {}, handleTagAssets, isShowReadMore = true} = props;
    const {longDescription, technicalDetails, tags = []} = data;
    const renderTechDetails = () => {
        if (isJsonString(technicalDetails)) {
            const techDetailsJson = JSON.parse(technicalDetails);
            return techDetailsJson.map((item, index) => {
                if (item.Image !== 'download') {
                    return (
                        <a
                            className="tech-details-link"
                            href={item.OnNavigateUrl}
                            key={`${item.Image}-${index}`}>
                            <div className="tech-details-container">
                                <i className={`icon-${item.Image} tech-link-image`} />
                                <span className="tech-details-text">{item.Text}</span>
                            </div>
                        </a>
                    );
                }
                return null;
            });
        }
        return <div dangerouslySetInnerHTML={{__html: slateToHtml(technicalDetails)}} />;
    };

    return (
        <Fragment>
            <section className="asset-desc asset-description-wrapper row">
                {isShowReadMore ? (
                    <ReadMoreText
                        className={`col-12 long-description`}
                        text={slateToHtml(longDescription)}
                        linesToShow={20}
                    />
                ) : (
                    <div className="col-12">
                        <div dangerouslySetInnerHTML={{__html: slateToHtml(longDescription)}} />
                    </div>
                )}
                <div className="col-12 technical-details">
                    <h3>
                        <Message code="messages.com.epicgames.plugin.store.asset.technical_details" />
                    </h3>
                    {renderTechDetails()}
                </div>
                <div className="clearfix" />
            </section>
            {!!tags.length && (
                <section className="asset-desc row tags">
                    <div className="col-12">
                        <span className="h3">
                            <Message code="messages.com.epicgames.plugin.store.asset.tags" />
                        </span>
                        <p>
                            {tags.map(tag => (
                                <a href="#" key={`tag-${tag.name}`} onClick={handleTagAssets(tag)}>
                                    {tag.name}
                                </a>
                            ))}
                        </p>
                    </div>
                </section>
            )}
        </Fragment>
    );
};
AssetDetailDesc.propTypes = {
    data: PropTypes.object,
    handleTagAssets: PropTypes.func,
    isShowReadMore: PropTypes.bool
};
export default AssetDetailDesc;
