/**
 * Created by Kaku.Guo on 2018/11/26.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {getMessage} from '../../index';
import {redirectLogin} from '../../../utils';
import {WishlistToggle} from '../../wishlist/WishlistToggle';
import styled from 'styled-components';
import {getLocale} from '@epic-core/common';
import PurchaseButton from '../../common/button/PurchaseButton';

/* eslint-disable react/jsx-no-target-blank*/

const AddCartBtns = styled.div`
    a.btn,
    button.wishlist-toggle {
        display: inline-block;
        margin: 0;
        vertical-align: bottom;
    }

    &&& {
        a.btn {
            max-width: 7em;
            min-width: 7em;
            border-radius: 0;
            height: 45px;
            margin-top: 1em;
        }
    }

    button.wishlist-toggle {
        height: 45px;
        margin-top: 1em;
        border-left: 1px solid #a57b00;
        width: 40px;
        padding: 0;
    }
`;

const AssetPurchaseInfo = props => {
    const {
        data = {},
        messages,
        isPurchasingOffer,
        isLoggedIn,
        isLauncher,
        offersInCart,
        globalBase,
        handleAddToCart,
        isAddingOffer,
        currentOffer,
        handlePurchase,
        setDialogOptions,
        hideDialog,
        reviewBtn
    } = props;
    const isShoppingCartEnabled =
        props.isShoppingCartEnabled && !(props.isLearnAsset && isLauncher);
    const {
        id,
        urlSlug,
        discounted,
        discount,
        price,
        priceValue,
        discountPrice,
        currencyCode,
        customAttributes,
        isCatalogItem,
        catalogItemId,
        isDownloadable,
        owned,
        isBuyAble,
        isSunset
    } = data;
    const itemPrice = discounted ? discountPrice : price;
    let buyLink = customAttributes ? customAttributes.BuyLink : null;
    buyLink = buyLink ? buyLink.value : null;
    const isAddingToCart = isAddingOffer && currentOffer === id;

    const addToCart = e => {
        if (isAddingToCart || isPurchasingOffer) {
            return;
        }
        const ele = $(e.target)
            .closest('.asset-details')
            .find('.image-gallery .image-gallery-swipe')
            .first();
        handleAddToCart({offerId: id, ele});
    };

    const getDownloadUrl = () =>
        isCatalogItem
            ? `com.epicgames.launcher://ue/marketplace/item/${catalogItemId}`
            : `com.epicgames.launcher://ue/marketplace/content/${urlSlug}`;

    const getSignInBtn = (isFree = false) => {
        return (
            <a
                className={`btn ${isFree && getLocale()}`}
                href="#"
                key="sign-in-btn"
                onClick={() => redirectLogin(globalBase)}>
                {getMessage(
                    messages,
                    `messages.com.epicgames.plugin.store.asset.signIn${isFree ? 'Free' : ''}`
                )}
            </a>
        );
    };

    const getDiscountedLayouts = () => {
        let elements = null;
        if (!discounted) {
            return elements;
        }
        if (isShoppingCartEnabled) {
            elements = (
                <span>
                    {getMessage(messages, 'epic.ue.marketplace.categories.onSale')}:&nbsp;
                    <span className="base-price">{price}</span>
                </span>
            );
        } else {
            elements = (
                <Fragment>
                    <span>
                        {getMessage(
                            messages,
                            'messages.com.epicgames.plugin.store.asset.list_price'
                        )}
                        :<span className="base-price">{price}</span>
                    </span>
                    <br />
                    <span>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.you_save')}
                        :<span className="base-price">{discount}</span>
                    </span>
                </Fragment>
            );
        }
        return (
            <div className={`discount-div ${isShoppingCartEnabled ? 'shopping-cart-enabled' : ''}`}>
                {elements}
            </div>
        );
    };

    const getFreeLayouts = () => {
        if (priceValue === 0 || itemPrice === '$0.00') {
            if (isLoggedIn) {
                const clazz = isPurchasingOffer ? 'btn disabled-btn' : 'btn';
                const elements = [
                    <PurchaseButton
                        key="free-btn"
                        className={clazz}
                        href="#"
                        onClick={handlePurchase(data)}>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.free')}
                    </PurchaseButton>
                ];

                if (isShoppingCartEnabled) {
                    elements.push(
                        <p key="or" className="or">
                            {getMessage(messages, 'messages.com.epicgames.plugin.store.message.or')}
                        </p>
                    );
                    const addCartClazz = `btn ${isAddingToCart ? 'adding' : ''} ${
                        isPurchasingOffer ? 'disabled-btn' : ''
                    }`;
                    const isInCart = offersInCart.includes(id);

                    if (isInCart) {
                        elements.push(
                            <AddCartBtns className="add-cart-btns" key="in-cart-btn">
                                <a href="#" className="btn disabled-btn">
                                    {getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.message.cart.in_cart'
                                    )}
                                </a>
                                <WishlistToggle key="wishlist-toggle" offerId={id} />
                            </AddCartBtns>
                        );
                    } else {
                        elements.push(
                            <AddCartBtns className="add-cart-btns" key="add-cart-btn">
                                <a href="#" className={addCartClazz} onClick={addToCart}>
                                    {getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.message.cart.add_to_cart'
                                    )}
                                </a>
                                <WishlistToggle key="wishlist-toggle" offerId={id} />
                            </AddCartBtns>
                        );
                    }
                }

                return elements;
            }
            return getSignInBtn(true);
        }
        return null;
    };

    const isInCart = offersInCart.includes(id);
    const getNormalLayouts = () => {
        if (priceValue !== 0 && itemPrice !== '$0.00') {
            if (isShoppingCartEnabled) {
                const elements = [];
                elements.push(
                    <span
                        className={`save-discount ${discounted ? 'discounted' : ''}`}
                        key="save-discount">
                        {itemPrice}
                    </span>
                );
                if (isLoggedIn) {
                    const clazz = isPurchasingOffer ? 'btn disabled-btn' : 'btn';
                    elements.push(
                        <PurchaseButton
                            className={clazz}
                            key="buy-now-btn"
                            onClick={handlePurchase(data)}>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.message.buy_now'
                            )}
                        </PurchaseButton>
                    );
                    elements.push(
                        <p className="or" key="p-or">
                            {getMessage(messages, 'messages.com.epicgames.plugin.store.message.or')}
                        </p>
                    );
                    if (isInCart) {
                        elements.push(
                            <AddCartBtns className="add-cart-btns" key="in-cart-btn">
                                <a href="#" className="btn disabled-btn shift-left">
                                    {getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.message.cart.in_cart'
                                    )}
                                </a>
                                <WishlistToggle key="wishlist-toggle" offerId={id} />
                            </AddCartBtns>
                        );
                    } else {
                        const addCartClazz = `btn ${isAddingToCart ? 'adding' : ''} ${
                            isPurchasingOffer ? 'disabled-btn' : ''
                        }`;
                        elements.push(
                            <AddCartBtns className="add-cart-btns" key="add-cart-btn">
                                <a className={addCartClazz} href="#" onClick={addToCart}>
                                    {getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.message.cart.add_to_cart'
                                    )}
                                </a>
                                <WishlistToggle key="wishlist-toggle" offerId={id} />
                            </AddCartBtns>
                        );
                    }
                } else {
                    elements.push(getSignInBtn());
                }
                return elements;
            }
            if (isLoggedIn) {
                const clazz = isPurchasingOffer
                    ? 'btn asset-get-btn disabled-btn'
                    : 'btn asset-get-btn';
                return (
                    <a className={clazz} href="#">
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.buy', [
                            itemPrice,
                            currencyCode === 'USD' ? currencyCode : ''
                        ])}
                    </a>
                );
            }
            return getSignInBtn();
        }
        return null;
    };

    const openLauncherDialog = () => {
        const options = {
            isVisible: true,
            headerText: 'messages.com.epicgames.plugin.store.asset.open_in_launcher.header',
            contentText: 'messages.com.epicgames.plugin.store.asset.open_in_launcher.content',
            saveText: 'messages.com.epicgames.plugin.store.asset.open_in_launcher.close',
            className: 'download-modal',
            onSave: () => hideDialog()
        };
        setDialogOptions(options);
    };

    const unavailableBtn = (
        <a href="#" className="btn asset-get-btn disabled-btn">
            {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.unavailable')}
        </a>
    );
    const externalBtn = (
        <a className="btn asset-get-btn" target="_blank" href={buyLink}>
            {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.vendor')}
        </a>
    );
    const downloadBtn = (
        <a className="btn" href={getDownloadUrl()} onClick={!isLauncher && openLauncherDialog}>
            {getMessage(
                messages,
                isLauncher
                    ? 'messages.com.epicgames.plugin.store.asset.button.download'
                    : 'messages.com.epicgames.plugin.store.asset.open_in_launcher'
            )}
        </a>
    );
    const sunsetBtn = (
        <a href="#" className="btn asset-get-btn disabled-btn">
            {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.notForSale')}
        </a>
    );

    const getLayouts = () => {
        if (!isDownloadable) {
            return unavailableBtn;
        }
        if (buyLink) {
            return externalBtn;
        }
        if (owned) {
            return (
                <Fragment>
                    {downloadBtn}
                    {reviewBtn}
                </Fragment>
            );
        }
        if (isBuyAble) {
            return (
                <Fragment>
                    {getDiscountedLayouts()}
                    {getFreeLayouts()}
                    {getNormalLayouts()}
                </Fragment>
            );
        }
        if (isSunset) {
            return sunsetBtn;
        }
        return unavailableBtn;
    };
    return <div className="purchase">{getLayouts()}</div>;
};
AssetPurchaseInfo.propTypes = {
    data: PropTypes.object,
    messages: PropTypes.object,
    isPurchasingOffer: PropTypes.bool,
    isShoppingCartEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isLauncher: PropTypes.bool,
    isLearnAsset: PropTypes.bool,
    offersInCart: PropTypes.array,
    globalBase: PropTypes.string,
    isAddingOffer: PropTypes.bool,
    handleAddToCart: PropTypes.func,
    handlePurchase: PropTypes.func,
    currentOffer: PropTypes.string,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    reviewBtn: PropTypes.any
};
export default AssetPurchaseInfo;
