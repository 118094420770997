import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cn from 'classnames';
import {getMessage} from '../../index';
import AssetPurchaseInfo from './AssetPurchaseInfo';
import {ReviewActions, ReviewDialogActions, UploadActions} from '../../../actions';
import {getReviewValidations} from '../../../data';
import {hasValue, isCategoryReviewable} from '../../../utils/Utils';
import {REVIEW_TYPES} from '../../../constants';

const mapDispatchToProps = dispatch => {
    return {
        addReview: model => dispatch(ReviewActions.addReview(model)),
        setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
        hideDialog: () => dispatch(ReviewDialogActions.hide(true)),
        uploadImages: model => dispatch(UploadActions.uploadQueue(model))
    };
};

const mapStateToProps = state => {
    const {detail, isLoadingDetail} = state.get('review').toJS();
    const {epicSellerOwner = ''} = state.get('config').toJS();
    const {isPostingAllowed} = state.get('user').toJS();
    return {
        reviewDetail: detail,
        isLoadingDetail,
        epicSellerOwner,
        isPostingAllowed
    };
};
@connect(mapStateToProps, mapDispatchToProps)
class AssetLauncherInfo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAdditionalMenu: false
        };
        this.dropDownRef = null;
    }
    componentDidMount() {
        const {data, initLauncherDownload} = this.props;
        if (data && data.catalogItemId) {
            initLauncherDownload(data);
        }
    }

    componentDidUpdate() {
        const {showAdditionalMenu} = this.state;
        if (showAdditionalMenu) {
            this.dropDownRef.focus();
        }
    }

    componentWillUnmount() {
        const {resetLauncherDownload} = this.props;
        resetLauncherDownload();
    }

    toggleAdditionalMenu = visible => e => {
        this.setState({
            showAdditionalMenu: visible
        });
    };

    runCommand = foundCommand => () => {
        const {executeCommand} = this.props;
        executeCommand(foundCommand);
        this.setState({
            showAdditionalMenu: false
        });
    };

    renderUnavailableBtn = () => {
        const {messages} = this.props;
        return (
            <button className="btn only-option" disabled="disabled">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.unavailable')}
            </button>
        );
    };

    renderCommands = commandLists => {
        let foundCommand = null;
        let commands = [];
        const additionalBtn = [];
        if (commandLists) {
            commands = commandLists.commands;
            for (let i = 0; i < commands.length; i++) {
                const command = commands[i];
                if (command.id === commandLists.preferred) {
                    foundCommand = command;
                } else {
                    additionalBtn.push(
                        <li key={command.id} onClick={this.runCommand(command)}>
                            {command.displayname}
                        </li>
                    );
                }
            }
        }
        const onlyOption = !(commands.length > 1);
        const {showAdditionalMenu} = this.state;

        if (foundCommand) {
            return (
                <div className="btn-group">
                    {this.renderCommandBtn(foundCommand, onlyOption)}
                    {!onlyOption && (
                        <button
                            type="button"
                            className="btn"
                            onClick={this.toggleAdditionalMenu(true)}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fa fa-caret-down" />
                            <span className="sr-only">Alternate Options</span>
                        </button>
                    )}
                    {!onlyOption && (
                        <ul
                            className={`dropdown-menu ${showAdditionalMenu ? 'show' : ''}`}
                            ref={ref => {
                                this.dropDownRef = ref;
                            }}
                            tabIndex={-1}
                            onBlur={this.toggleAdditionalMenu(false)}>
                            {additionalBtn}
                        </ul>
                    )}
                </div>
            );
        }
        return this.renderUnavailableBtn();
    };

    renderCommandBtn = (foundCommand, onlyOption) => {
        return (
            <button
                className={`btn ${onlyOption ? 'only-option' : ''}`}
                onClick={this.runCommand(foundCommand)}>
                {foundCommand.displayname}
            </button>
        );
    };

    showReviewDialog = () => {
        const {
            isPostingAllowed,
            setDialogOptions,
            hideDialog,
            uploadImages,
            addReview,
            data = {},
            reviewDetail,
            messages
        } = this.props;
        const {seller = {}, id} = data;
        const hasReviewed = this.hasReview(reviewDetail);
        const options = isPostingAllowed
            ? {
                  headerText: `messages.com.epicgames.plugin.store.asset.reviews.btn${
                      hasReviewed ? '.edit' : '.add'
                  }`,
                  saveText: `messages.com.epicgames.plugin.store.asset.reviews.dialog.submit${
                      hasReviewed ? '.edit' : '.add'
                  }`,
                  summaryPlaceholder: 'messages.com.epicgames.plugin.store.asset.reviews.summary',
                  descPlaceholder: 'messages.com.epicgames.plugin.store.asset.reviews.desc',
                  validations: getReviewValidations(messages, false, 8000, true),
                  isVisible: true,
                  isSummaryEnable: true,
                  isRatingEnable: true,
                  isDescEnable: true,
                  isImageEnable: true,
                  contentMaxLength: 8000,
                  defaultValue: reviewDetail,
                  onSave: value => {
                      const nextData = {
                          ...value,
                          offerId: id,
                          targetOwner: seller.owner,
                          type: REVIEW_TYPES.REVIEW
                      };
                      if (nextData.images) {
                          uploadImages({
                              type: REVIEW_TYPES.REVIEW,
                              files: nextData.images,
                              onSuccess: images => {
                                  addReview({...nextData, images});
                              }
                          });
                      } else {
                          addReview(nextData);
                      }
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    hasReview = reviewDetail => {
        return hasValue(reviewDetail) && !!reviewDetail.content;
    };

    getReviewBtn = () => {
        const {reviewDetail, messages, isLoadingDetail} = this.props;
        if (isLoadingDetail) {
            return <div className="loading" />;
        }
        const hasReviewed = this.hasReview(reviewDetail);
        return (
            <a
                href="#"
                className={cn('btn', !hasReviewed && 'asset-reviews__btn--new')}
                onClick={this.showReviewDialog}>
                {getMessage(
                    messages,
                    `messages.com.epicgames.plugin.store.asset.reviews.btn${
                        hasReviewed ? '.edit' : '.add'
                    }`
                )}
            </a>
        );
    };

    render() {
        const {
            data,
            commandLists,
            isLauncher,
            currentAccountId,
            messages,
            epicSellerOwner
        } = this.props;
        const {owned, seller = {}, categories = []} = data;

        let reviewBtn = null;
        if (
            owned &&
            hasValue(seller) &&
            seller.owner !== currentAccountId &&
            seller.owner !== epicSellerOwner &&
            isCategoryReviewable(categories)
        ) {
            reviewBtn = (
                <Fragment>
                    <p className="or" key="p-or">
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.message.or')}
                    </p>
                    {this.getReviewBtn()}
                </Fragment>
            );
        }

        if (isLauncher && owned) {
            return (
                <div className="btn-group-wrap">
                    {this.renderCommands(commandLists)}
                    {reviewBtn}
                </div>
            );
        }
        return <AssetPurchaseInfo {...this.props} reviewBtn={reviewBtn} />;
    }
}

AssetLauncherInfo.propTypes = {
    data: PropTypes.object,
    messages: PropTypes.object,
    isLoadingDetail: PropTypes.bool,
    commandLists: PropTypes.object,
    isLauncher: PropTypes.bool,
    executeCommand: PropTypes.func,
    initLauncherDownload: PropTypes.func,
    resetLauncherDownload: PropTypes.func,
    currentAccountId: PropTypes.string,
    addReview: PropTypes.func,
    uploadImages: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    reviewDetail: PropTypes.object,
    epicSellerOwner: PropTypes.string
};
export default AssetLauncherInfo;
