import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Review, getMessage, Button, ReviewItem} from '../../index';
import {ReviewActions, ReviewDialogActions, UploadActions} from '../../../actions';
import {getReviewValidations} from '../../../data';
import {REVIEW_TYPES} from '../../../constants';
import {loginInterceptor, hasValue} from '../../../utils';

const mapDispatchToProps = dispatch => ({
    addReview: model => dispatch(ReviewActions.addReview(model)),
    getReviews: model => dispatch(ReviewActions.getReviews(model)),
    setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
    hideDialog: () => dispatch(ReviewDialogActions.hide(true)),
    uploadImages: model => dispatch(UploadActions.uploadQueue(model))
});

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const reviewData = state.get('review').toJS();
    const {data} = state
        .get('asset')
        .get('data')
        .toJS();
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    const globalBase = state.get('config').get('globalBase');
    return {
        messages,
        reviewData,
        assetData: data,
        isLoggedIn,
        isPostingAllowed,
        accountId: accountInfo.id,
        globalBase
    };
};

const SORT_OPTIONS = [
    {
        label: 'messages.com.epicgames.plugin.store.filter.newest_first',
        value: 'CREATEDAT',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.filter.oldest_first',
        value: 'CREATEDAT',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_desc',
        value: 'HELPFULNUMBER',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_asc',
        value: 'HELPFULNUMBER',
        dir: 'ASC'
    }
];

const AssetQuestions = props => {
    const type = REVIEW_TYPES.QUESTION;
    const {
        messages,
        assetData = {},
        reviewData = {},
        isLoggedIn,
        isPostingAllowed,
        accountId,
        hideDialog,
        getReviews,
        addReview,
        uploadImages,
        setDialogOptions,
        showLegacyComments,
        globalBase,
        preventScroll
    } = props;
    const {id, owned, seller = {}, customAttributes = {}} = assetData;
    let buyLink = customAttributes.BuyLink;
    buyLink = buyLink ? buyLink.value : null;
    const sellerId = seller.owner;
    const {data = {}} = reviewData;
    const selectOptions = SORT_OPTIONS.map(it => ({
        text: getMessage(messages, it.label),
        value: `${it.value}_${it.dir}`
    }));
    const enableAsk = accountId !== sellerId;

    const onSearch = params => {
        if (id) {
            getReviews({...params, type, offerId: id});
        }
    };

    const showQuestionAddDialog = () => {
        const options = isPostingAllowed
            ? {
                  isVisible: true,
                  validations: getReviewValidations(messages, true, 8000),
                  headerText: `messages.com.epicgames.plugin.store.asset.${type}.dialog.header`,
                  saveText: `messages.com.epicgames.plugin.store.asset.${type}.dialog.submit`,
                  isSummaryEnable: true,
                  summaryPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.summary`,
                  isDescEnable: true,
                  isDescRequired: true,
                  descPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.desc`,
                  isImageEnable: true,
                  contentMaxLength: 8000,
                  onSave: value => {
                      const nextData = {...value, offerId: id, targetOwner: sellerId, type};
                      if (nextData.images) {
                          uploadImages({
                              type,
                              files: nextData.images,
                              onSuccess: images => {
                                  addReview({...nextData, images});
                              }
                          });
                      } else {
                          addReview(nextData);
                      }
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    const renderPostBtn = () => {
        if (!enableAsk) return null;
        return (
            <Button onClick={loginInterceptor(isLoggedIn, globalBase, showQuestionAddDialog)}>
                {getMessage(
                    messages,
                    'messages.com.epicgames.plugin.store.asset.questions.btn.add'
                )}
            </Button>
        );
    };

    const renderList = () => {
        const {elements = []} = data;
        //for external product, only the seller can reply to a question
        const isReplyEnabled = (buyLink && sellerId === accountId) || (!buyLink && !!owned);
        return elements.map((it, idx) => (
            <ReviewItem type={type} key={`review-item-${idx}`} data={{isReplyEnabled, ...it}} />
        ));
    };

    const preventScrollVal = !hasValue(assetData) || preventScroll;

    return (
        <Review
            onSearch={onSearch}
            preventScroll={preventScrollVal}
            renderPostBtn={renderPostBtn}
            renderEmptyBtn={renderPostBtn}
            isLegacyCommentsEnabled={false}
            showLegacyComments={showLegacyComments}
            type={type}
            messages={messages}
            reviewData={reviewData}
            selectOptions={selectOptions}>
            {renderList()}
        </Review>
    );
};

AssetQuestions.propTypes = {
    accountId: PropTypes.string,
    messages: PropTypes.object,
    assetData: PropTypes.object,
    reviewData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    getReviews: PropTypes.func,
    addReview: PropTypes.func,
    uploadImages: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    showLegacyComments: PropTypes.func,
    globalBase: PropTypes.string,
    preventScroll: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetQuestions);
