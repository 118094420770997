import React from 'react';
import Helmet from 'react-helmet';
import parse from 'url-parse';
import root from 'window-or-global';
import {useLocalization, useLocalizationLocale} from '@epic-core/localization';

import {getCanonicalUrl} from '../../utils/metaTagUtils';

interface ImageProps {
    src: string;
    altText?: string;
    alt?: string;
    width?: string;
    height?: string;
}

interface SocialProps {
    image: string;
    title: string;
    description: string;
    alt: string;
}

export interface SeoProps {
    og: {
        image?: ImageProps[];
        title: string;
        description: string;
    };
    twitter?: SocialProps;
    image: ImageProps;
    description: string;
    title: string;
}

export const CouponDetailSeo: React.FC<{seo: SeoProps}> = ({seo}: {seo: SeoProps}) => {
    const ogImages: React.ReactElement[] = [];
    const getMessage = useLocalization();
    const {og, twitter, image, description, title} = seo;
    const url = getCanonicalUrl();
    const locale = useLocalizationLocale();
    const canonical = parse(root.location.href);
    canonical.set('hash', '');
    canonical.set('query', '');

    if (og?.image?.length) {
        og.image.forEach((ogimg, i) => {
            ogImages.push(
                <meta
                    id="page-meta-og:image"
                    data-testid="page-meta-og:image"
                    name="og:image"
                    property="og:image"
                    content={ogimg.src}
                    key={`page-meta-og:image-key-${i}`}
                />
            );
            ogImages.push(
                <meta
                    id="page-meta-og:image:alt"
                    data-testid="page-meta-og:image:alt"
                    name="og:image:alt"
                    property="og:image:alt"
                    content={`${ogimg.alt ?? ''}`}
                    key={`page-meta-og:image:alt-key-${i}`}
                />
            );
            ogImages.push(
                <meta
                    id="page-meta-og:image:width"
                    data-testid="page-meta-og:image:width"
                    name="og:image:width"
                    property="og:image:width"
                    content={`${ogimg.width ?? 0}`}
                    key={`page-meta-og:image:width-key-${i}`}
                />
            );
            ogImages.push(
                <meta
                    id="page-meta-og:image:height"
                    data-testid="page-meta-og:image:height"
                    name="og:image:height"
                    property="og:image:height"
                    content={`${ogimg.height ?? 0}`}
                    key={`page-meta-og:image:height-key-${i}`}
                />
            );
        });
    } else {
        ogImages.push(
            <meta
                id="page-meta-og:image"
                data-testid="page-meta-og:image"
                name="og:image"
                property="og:image"
                content={image.src}
                key={'page-meta-og:image'}
            />
        );
        ogImages.push(
            <meta
                id="page-meta-og:image:alt"
                data-testid="page-meta-og:image:alt"
                name="og:image:alt"
                property="og:image:alt"
                content={image.altText}
                key="page-meta-og:image:alt"
            />
        );
    }
    return (
        <Helmet defaultTitle={title}>
            <link
                id="page-link-canonical"
                data-testid="page-link-canonical"
                rel="canonical"
                href={canonical.toString()}
            />
            <meta
                id="page-meta-url"
                data-testid="page-meta-url"
                name="url"
                property="url"
                content={url}
            />
            <meta
                id="page-meta-robots"
                data-testid="page-meta-robots"
                name="robots"
                property="robots"
                content={'index,follow'}
            />
            <meta
                id="page-meta-description"
                data-testid="page-meta-description"
                name="description"
                content={description}
            />

            <meta
                id="page-meta-og:url"
                data-testid="page-meta-og:url"
                name="og:url"
                property="og:url"
                content={url}
            />
            <meta
                id="page-meta-og:locale"
                data-testid="page-meta-og:locale"
                name="og:locale"
                property="og:locale"
                content={locale}
            />
            <meta
                id="page-meta-og:site_name"
                data-testid="page-meta-og:site_name"
                name="og:site_name"
                property="og:site_name"
                content={getMessage('epic.marketplace.coupons.social.meta.site_name')}
            />
            <meta
                id="page-meta-og:title"
                data-testid="page-meta-og:title"
                name="og:title"
                property="og:title"
                content={og?.title ?? title}
            />
            <meta
                id="page-meta-og:description"
                data-testid="page-meta-og:description"
                name="og:description"
                property="og:description"
                content={og?.description ?? description}
            />
            {ogImages.map(c => c)}
            <meta
                id="page-meta-twitter:site"
                data-testid="page-meta-twitter:site"
                name="twitter:site"
                property="twitter:site"
                content={getMessage('epic.marketplace.coupons.social.meta.twitter_account')}
            />
            <meta
                id="page-meta-twitter:card"
                data-testid="page-meta-twitter:card"
                name="twitter:card"
                property="twitter:card"
                content={'summary_large_image'}
            />
            <meta
                id="page-meta-twitter:title"
                data-testid="page-meta-twitter:title"
                name="twitter:title"
                property="twitter:title"
                content={twitter?.title ?? title}
            />
            <meta
                id="page-meta-twitter:description"
                data-testid="page-meta-twitter:description"
                name="twitter:description"
                property="twitter:description"
                content={twitter?.description ?? description}
            />
            <meta
                id="page-meta-twitter:image"
                data-testid="page-meta-twitter:image"
                name="twitter:image"
                property="twitter:image"
                content={twitter?.image ?? image.src}
            />
            <meta
                id="page-meta-twitter:image:alt"
                data-testid="page-meta-twitter:image:alt"
                name="twitter:image:alt"
                property="twitter:image:alt"
                content={twitter?.alt ?? image.altText}
            />
        </Helmet>
    );
};
