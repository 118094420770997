import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useLocalizationLocale, useLocalization} from '@epic-core/localization';

import {Button} from '..';
import {CouponDetails} from '../../shared/coupons/types';
import {getAssetPath} from '../../utils';
import {ImageType} from '../../shared/coupons/coupon.hooks';

const CouponListItemWrapper = styled.div`
    height: 300px;
    padding: 0 0 60px 0;
    display: flex;
    margin-top: 60px;
    border-bottom: solid 1px #3b3c3d;
`;

const Content = styled.div`
    margin-left: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const CouponDesc = styled.div`
    flex: 1;
    h3 {
        font-size: 20px;
        color: white;
        margin-bottom: 10px !important;
        font-weight: 600 !important;
    }
    p {
        font-size: 14px;
        color: #ccc;
    }
`;

const Image = styled.div<{src: string}>`
    background: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url(${p => p.src});
    width: 428px;
    min-width: 428px;
    height: 240px;
`;

const ImagePlaceholder = styled.div`
    width: 428px;
    height: 240px;
    display: flex;
    jusity-content: center;
    align-items: center;
    background-color: rgba(100, 100, 100, 0.2);
`;

const ButtonWrapper = styled.div`
    width: 200px;
`;

export const CouponListItem = (coupon: CouponDetails) => {
    const {amount, symbol} = coupon.consumptionMetadata.amountDisplay;
    const displayAmount = `${symbol}${amount}`;
    const country = useLocalizationLocale();
    const getMessage = useLocalization();
    const expiredDate = moment(coupon.endDate).format(
        country === 'en-US' ? 'MMM D, YYYY' : 'YYYY/MM/DD'
    );
    const {minSalesPriceDisplay} = coupon.consumptionMetadata;
    const minSalesDisplayAmount = `${minSalesPriceDisplay.symbol}${minSalesPriceDisplay.amount}`;
    const {voucherImages, voucherLink} = coupon.salesEvent;
    const vocherImage =
        voucherImages.length &&
        voucherImages.find(image => image && image.type === ImageType.SMALL);
    return (
        <CouponListItemWrapper>
            <a href={voucherLink} target="_blank" rel="noopener noreferrer">
                {vocherImage ? (
                    <Image src={vocherImage?.url} />
                ) : (
                    <ImagePlaceholder>
                        <Image src={getAssetPath('logo-epic.svg')} />
                    </ImagePlaceholder>
                )}
            </a>
            <Content>
                <CouponDesc>
                    <h3>{`${coupon.salesEvent.eventName} - ${displayAmount}`}</h3>
                    <p>{getMessage('epic.marketplace.coupons.expires.message', [expiredDate])}</p>
                    <p>
                        {getMessage('epic.marketplace.coupons.products.price.higher', [
                            minSalesDisplayAmount
                        ])}
                    </p>
                </CouponDesc>
                <ButtonWrapper>
                    <a href={voucherLink} target="_blank" rel="noopener noreferrer">
                        <Button>{getMessage('epic.marketplace.coupons.button.see_detail')}</Button>
                    </a>
                </ButtonWrapper>
            </Content>
        </CouponListItemWrapper>
    );
};
