import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Review, getMessage, Button, ReviewItem} from '../../index';
import {ReviewActions, ReviewDialogActions, UploadActions} from '../../../actions';
import {getReviewValidations} from '../../../data';
import {hasValue} from '../../../utils/Utils';
import {REVIEW_TYPES} from '../../../constants';

const mapDispatchToProps = dispatch => ({
    getReviews: model => dispatch(ReviewActions.getReviews(model)),
    addReview: model => dispatch(ReviewActions.addReview(model)),
    setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
    hideDialog: () => dispatch(ReviewDialogActions.hide(true)),
    uploadImages: model => dispatch(UploadActions.uploadQueue(model))
});

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const reviewData = state.get('review').toJS();
    const {data} = state
        .get('asset')
        .get('data')
        .toJS();
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    return {
        messages,
        reviewData,
        assetData: data,
        isLoggedIn,
        isPostingAllowed,
        accountId: accountInfo.id
    };
};

const SORT_OPTIONS = [
    {
        label: 'messages.com.epicgames.plugin.store.filter.newest_first',
        value: 'CREATEDAT',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.filter.oldest_first',
        value: 'CREATEDAT',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_desc',
        value: 'HELPFULNUMBER',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_asc',
        value: 'HELPFULNUMBER',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.reviews.sort.rating_desc',
        value: 'RATING',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.reviews.sort.rating_asc',
        value: 'RATING',
        dir: 'ASC'
    }
];

const AssetReviews = props => {
    const type = REVIEW_TYPES.REVIEW;
    const {
        messages,
        assetData = {},
        reviewData = {},
        isLoggedIn,
        isPostingAllowed,
        accountId,
        getReviews,
        addReview,
        uploadImages,
        setDialogOptions,
        hideDialog,
        preventScroll,
        showLegacyComments
    } = props;
    const {id, owned, seller = {}} = assetData;
    const sellerId = seller.owner;
    const {detail, data = {}} = reviewData;
    const enableAddReview = isLoggedIn && owned && accountId !== sellerId && !hasValue(detail);
    const selectOptions = SORT_OPTIONS.map(it => ({
        text: getMessage(messages, it.label),
        value: `${it.value}_${it.dir}`
    }));

    const onSearch = params => {
        if (id) {
            getReviews({...params, type, offerId: id});
        }
    };

    const showReviewDialog = () => {
        const options = isPostingAllowed
            ? {
                  headerText: `messages.com.epicgames.plugin.store.asset.${type}.btn.add`,
                  saveText: `messages.com.epicgames.plugin.store.asset.${type}.dialog.submit.add`,
                  summaryPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.summary`,
                  descPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.desc`,
                  validations: getReviewValidations(messages, false, 8000, true),
                  isVisible: true,
                  isSummaryEnable: true,
                  isRatingEnable: true,
                  isDescEnable: true,
                  isImageEnable: true,
                  contentMaxLength: 8000,
                  defaultValue: detail,
                  onSave: value => {
                      const nextData = {...value, offerId: id, targetOwner: sellerId, type};
                      if (nextData.images) {
                          uploadImages({
                              type,
                              files: nextData.images,
                              onSuccess: images => {
                                  addReview({...nextData, images});
                              }
                          });
                      } else {
                          addReview(nextData);
                      }
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };

    const renderPostBtn = () => {
        if (!enableAddReview) return null;
        return (
            <Button className="asset-reviews__btn--new" onClick={showReviewDialog}>
                {getMessage(messages, `messages.com.epicgames.plugin.store.asset.${type}.btn.add`)}
            </Button>
        );
    };

    const renderList = () => {
        const {elements = []} = data;
        return elements.map((it, idx) => (
            <ReviewItem
                type={type}
                key={`review-item-${idx}`}
                data={{isReplyEnabled: true, ...it}}
            />
        ));
    };

    const preventScrollVal = !hasValue(assetData) || preventScroll;
    return (
        <Review
            onSearch={onSearch}
            preventScroll={preventScrollVal}
            renderEmptyBtn={renderPostBtn}
            isLegacyCommentsEnabled={false}
            showLegacyComments={showLegacyComments}
            type={type}
            messages={messages}
            reviewData={reviewData}
            selectOptions={selectOptions}>
            {renderList()}
        </Review>
    );
};

AssetReviews.propTypes = {
    messages: PropTypes.object,
    assetData: PropTypes.object,
    reviewData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    accountId: PropTypes.string,
    getReviews: PropTypes.func,
    addReview: PropTypes.func,
    uploadImages: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    preventScroll: PropTypes.bool,
    showLegacyComments: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetReviews);
