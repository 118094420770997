import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Paginator, getMessage, SortSelect, FullScreenModal, FullScreenReducer } from '../index';
import { getDefaultReviewParams } from '../../constants';
import { scrollToReviewTab } from './reviewUtils';

const Review = (props) => {
    const { messages, onSearch, reviewData = {}, selectOptions, type, renderPostBtn, renderEmptyBtn, children, className,
        isLegacyCommentsEnabled, showLegacyComments, preventScroll } = props;
    const prefixCls = 'asset-reviews';
    const { isLoading, data = {}, error, listParams = {} } = reviewData;
    const { paging = {}, elements } = data;
    const { start, count, total } = paging;
    const pageCount = Math.ceil(total / count);
    const { sortBy, sortDir } = listParams;
    const classNames = cn(prefixCls, className);

    useEffect(() => {
        if (!preventScroll) {
            scrollToReviewTab();
        }
    }, []);

    useEffect(() => {
        onSearch({ ...getDefaultReviewParams() });
    }, []);

    const handlePageChange = (startPage, itemsPerPage) => {
        const newStart = (startPage * itemsPerPage) - itemsPerPage;
        onSearch({ ...listParams, start: newStart, count: itemsPerPage });
        scrollToReviewTab();
    };

    const [fullScreenState, fullScreenDispatch] = useReducer(FullScreenReducer);

    const renderList = () => {
        if (isLoading) {
            return <div className="loading" />;
        } else if (error && error.errorCode) {
            return getMessage(messages, error.errorCode);
        } else if (elements && elements.length) {
            return children.map(it => React.cloneElement(it, { fullScreenDispatch }));
        }
        return (
            <div className={`${prefixCls}__list__empty`}>
                <p>{getMessage(messages, `messages.com.epicgames.plugin.store.asset.${type}.empty`)}</p>
                {renderEmptyBtn && renderEmptyBtn()}
            </div>
        );
    };

    const getSortMessage = () => {
        if (total === 1) {
            return `messages.com.epicgames.plugin.store.asset.${type}.sort.single.title`;
        }
        return `messages.com.epicgames.plugin.store.asset.${type}.sort.title`;
    };

    return (
        <div className={classNames}>
            <div className={`${prefixCls}__header`}>
                {total ? (
                    <SortSelect
                        title={getMessage(messages, getSortMessage(), [total])}
                        options={selectOptions}
                        value={`${sortBy}_${sortDir}`}
                        onChange={value => onSearch({ ...listParams, ...value })}
                    />
                ) : (
                    <div />
                )}
                {!!total && renderPostBtn && renderPostBtn()}
            </div>
            <div className={`${prefixCls}__list`}>{renderList()}</div>
            <div className={`${prefixCls}__footer`}>
                {!!isLegacyCommentsEnabled && (
                    <a className={`${prefixCls}__legacy`} onClick={showLegacyComments}>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.view_legacy')}
                    </a>
                )}
                {!!total && (
                    <Paginator
                    defaultPageSize={10}
                    pageSizeOptions={['5', '10', '25', '50']}
                    pageCount={pageCount}
                    current={start === 0 ? 1 : Math.floor(start / count) + 1}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    total={total}
                    pageSize={count || 10}
                />
                )}
            </div>
            <FullScreenModal options={fullScreenState} dispatch={fullScreenDispatch} />
        </div>
    );
};

Review.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.object.isRequired,
    reviewData: PropTypes.object,
    selectOptions: PropTypes.array,
    onSearch: PropTypes.func,
    renderPostBtn: PropTypes.func,
    renderEmptyBtn: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.oneOf(['reviews', 'questions']).isRequired,
    isLegacyCommentsEnabled: PropTypes.bool,
    showLegacyComments: PropTypes.func,
    preventScroll: PropTypes.bool
};

export default Review;
