import * as React from 'react';
import {LoadingDots} from '@epic-ui/icons';

import {useEula, useEulaAccept, useEulaDialog, isEulaStarted} from "../../../shared/eula/eula.hooks";

interface Props {
    identityId: string,
    children: React.ReactNode,
    onClick: (args) => void;
    disabled: boolean;
}

export const PurchaseConnectedButton = React.forwardRef((
    props: Props,
    ref: React.Ref<HTMLDivElement>
) => {
    const {identityId, ...rest} = props;
    const { loading, accepted, loaded } = useEula(identityId);
    const callback = props.onClick;
    const {openEulaDialog} = useEulaDialog();
    const { checkHasAcceptedEula, loadingAcceptance } = useEulaAccept(identityId);
    const handlerClick= async(...args) => {
        if (loading) {
            return handlerClick();
        }
        if (isEulaStarted() && (!accepted && loaded) ) {
            const checked = await checkHasAcceptedEula();
            if (!checked) {
                openEulaDialog(callback);
                return;
            }
        }
        // @ts-ignore
        props.onClick(...args);
    }
    return (
        // @ts-ignore
        <button ref={ref} {...rest} disabled={props.disabled || loadingAcceptance} onClick={handlerClick}>
            {loadingAcceptance ? <LoadingDots /> : props.children}
        </button>
    );
});
