/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

@connect(mapStateToProps, mapDispatchToProps)
class EventSaleView extends BasePageView {
    constructor(props) {
        const {eventSaleLabel} = props;
        const staticParams = {
            saleType: 'event'
        };
        const extraParams = {
            contentHeader: eventSaleLabel || {
                code: 'epic.ue.marketplace.categories.eventSale'
            },
            descriptionCode: 'epic.ue.marketplace.meta.sale'
        };
        if (eventSaleLabel && !eventSaleLabel.code) {
            extraParams.helmetData = {
                title: eventSaleLabel
            };
        } else {
            extraParams.pageTitleCode = eventSaleLabel
                ? eventSaleLabel.code
                : 'epic.ue.marketplace.categories.eventSale';
        }

        super(props, staticParams, extraParams);
    }
}
export default withRouter(EventSaleView);
