import * as React from 'react';
import {connect} from 'react-redux';

import {PurchaseConnectedButton} from './PurchaseButton.connected';

const mapStateToProps = state => {
    const {accountInfo, isLoggedIn} = state.get('user').toJS();
    return {
        identityId: accountInfo.id,
        isLoggedIn
    };
}

export default connect(mapStateToProps)(PurchaseConnectedButton);
