import React, {useState, useEffect, useCallback} from 'react';
import {UnrealEngineNavigation} from '@wf-components/navigation-react';
import {
    NavSearchEvent,
    NavCtaClickEvent,
    NavCustomAccountLink
} from '@wf-components/navigation-consumer';
import {getLocale} from '@epic-core/common';
import {useAccountStore} from 'epic-ue-shared';
import {useLocation} from 'react-router-dom';
import root from 'window-or-global';
import {useEnterpriseSeats} from 'epic-ue-header-footer/src/shared/enterprise.hooks';

const isPathDisabled = (currentPath: string, disablePaths?: string[]) => {
    let disable = false;
    if (disablePaths && disablePaths?.length) {
        for (let i = 0; i < disablePaths?.length; i++) {
            if (currentPath.includes(disablePaths[i])) {
                disable = true;
            }
        }
    }
    return disable;
};

interface Props {
    disablePaths?: string[];
}

export const GlobalNavWrapper = ({disablePaths}: Props): JSX.Element | null => {
    const {account} = useAccountStore();
    const location = useLocation();
    const pathname = location.pathname;
    const locale = getLocale();
    const blackListLangCodes = ['de', 'es-ES', 'fr', 'pt-BR'];
    const clientConfig = root.clientEnvConfig || {};
    const serverUrl = clientConfig.EPIC_SERVER_URL;

    const [accountState, setAccountState] = useState('');

    useEffect(() => {
        if (root.location.href) {
            try {
                const url = new URL(root.location.href);
                if (url) {
                    url.searchParams.append('state', encodeURIComponent(pathname));
                    url.searchParams.append('lang', locale.replace(/-/, '_'));
                    setAccountState(url.searchParams.toString());
                }
            } catch (e) {
                console.log('Failed to parse href', e);
            }
        }
    }, [pathname]);

    const {hasAccess: hasManagementAccess, title: manageSeatTitle} = useEnterpriseSeats();
    const customAccountLinks: NavCustomAccountLink[] = [];
    if (hasManagementAccess && manageSeatTitle) {
        customAccountLinks.push({
            id: 'custom-enterprise-management',
            locale,
            label: manageSeatTitle,
            key: 'enterpriseManagement',
            hrefTemplate: `${serverUrl}/enterprise-management?lang=${locale}`
        });
    }

    const navOnSearch = useCallback((e: CustomEvent<NavSearchEvent>) => {
        if (e.detail.value) {
            e.detail.originalEvent?.preventDefault();
            root.location.href = `${serverUrl}/search?keyword=${e.detail.value}`;
        }
    }, []);

    const ctaOnClick = useCallback((e: CustomEvent<NavCtaClickEvent>) => {
        if (e.detail) {
            e.detail.originalEvent?.preventDefault();
            root.location.href = `${serverUrl}/download?start=true`;
        }
    }, []);

    const disable = isPathDisabled(pathname, disablePaths);
    if (disable) return null;

    return (
        <UnrealEngineNavigation
            domain={serverUrl}
            blackListLangCodes={blackListLangCodes}
            forceActiveMainLinkKey={'marketplace-parent'}
            locale={locale}
            isLoggedIn={account.isLoggedIn}
            displayName={account.displayName}
            navOnSearch={navOnSearch}
            ctaOnClick={ctaOnClick}
            customAccountLinks={customAccountLinks}
            signInLinkHref={`/marketplace/login?${accountState}`}
            signOutLinkHref={`/marketplace/logout?${accountState}`}
        />
    );
};
