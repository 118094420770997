import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {usePageTitle} from '../../shared/pageTitle.hooks';
import CouponListPageWrapper from './Couponlistpage.connected';

const mapStateToPorps = state => {
    const {accountInfo} = state.get('user').toJS();
    return {
        identityId: accountInfo.id
    };
};

const CouponListPage = ({identityId}: {identityId: string}) => {
    const {setPageTitle} = usePageTitle();

    useEffect(() => {
        setPageTitle('messages.com.epicgames.plugin.store.title.coupons');
        return () => setPageTitle('messages.com.epicgames.plugin.store.title.coupons');
    }, []);

    return <CouponListPageWrapper identityId={identityId} />;
};

export default connect(mapStateToPorps)(CouponListPage);
