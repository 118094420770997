/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React, {Component, Fragment} from 'react';
import root from 'window-or-global';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import qs from 'query-string';
import {Helmet} from 'react-helmet';
import {UnrealLoading} from 'epic-ue-loading';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {HomeSection, getMessage} from '../../components';
import {AssetActions, CmsActions, ConfigActions, ShoppingCartActions} from '../../actions';
import {combinePageTitle} from '../../utils';
import {getHelmetMetaTags} from '../../utils/metaTagUtils';

const mapDispatchToProps = dispatch => {
    return {
        getPageData: () => {
            dispatch(AssetActions.getHomeData());
        },
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        },
        handleAddToCart: model => {
            dispatch(ShoppingCartActions.addOffer(model));
        },
        getCmsPageData: slug => {
            dispatch(CmsActions.getCmsPageData(slug));
        }
    };
};
const mapStateToProps = state => {
    const {isLoading: isLoadingCms, cmsPageData} = state.get('cms').toJS();
    const {isLoadingHomeData, homeData} = state.get('asset').toJS();
    const {isLauncher} = state.get('config').toJS();
    const {isAddingOffer, currentOffer, offerIds} = state.get('shoppingCart').toJS();
    const messages = state
        .get('localization')
        .get('messageProperties')
        .toJS();
    const {locale} = state.get('localization').toJS();
    return {
        cmsPageData,
        messages,
        isLoading: isLoadingCms || isLoadingHomeData,
        data: homeData,
        isLauncher,
        isAddingOffer,
        currentOffer: currentOffer ? currentOffer.offerId : '',
        offersInCart: offerIds,
        locale
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class HomePage extends Component {
    componentDidMount() {
        const {
            setConfig,
            getCmsPageData,
            getPageData,
            data = {},
            location: {pathname}
        } = this.props;
        setConfig({
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.marketplace'
            },
            isHomeSuppressed: true
        });

        //Only load data if its not already loaded
        if (data && Object.keys(data).length === 0) {
            getPageData();
        }

        const isServerSide = root.__server_side_render;
        if (!isServerSide) {
            getCmsPageData(pathname);
        }
    }

    componentWillUnmount() {
        this.props.setConfig({
            isHomeSuppressed: false
        });
    }

    getFreePageSearch = () => {
        const params = {
            priceRange: '[0,0]'
        };
        return `?${qs.stringify(params)}`;
    };

    getHelmetElement() {
        const {cmsPageData, isLoading, data, locale, messages} = this.props;
        if (cmsPageData && Object.keys(cmsPageData).length) {
            return getHelmetForMetaTags(cmsPageData, 'UE Marketplace');
        } else if (!cmsPageData && !isLoading) {
            // if no cmsPageData, fallback to in-app helmet data
            const featured = data.featured || [];
            const firstFeatured = featured[0] || {};
            const pageTitle = combinePageTitle(
                messages,
                getMessage,
                'messages.com.epicgames.plugin.store.title.marketplace'
            );
            const helmetTags = getHelmetMetaTags({
                title: pageTitle,
                description: getMessage(messages, 'epic.ue.marketplace.meta.home'),
                image: firstFeatured.featured || firstFeatured.thumbnail,
                locale
            });

            return <Helmet {...helmetTags} />;
        }
        return null;
    }

    render() {
        const {
            isLoading,
            data = {},
            messages,
            handleAddToCart,
            isAddingOffer,
            currentOffer,
            offersInCart,
            isLauncher
        } = this.props;

        if (isLoading) {
            return <UnrealLoading fillViewHeight={false} />;
        }

        const {featured, free, onSale, newReleases} = data;

        const commonProps = {
            messages,
            handleAddToCart,
            isAddingOffer,
            currentOffer,
            offersInCart,
            isLauncher
        };

        return (
            <Fragment>
                {this.getHelmetElement()}
                <HomeSection
                    className="offset-top"
                    data={featured}
                    isFeatured={true}
                    header="messages.com.epicgames.plugin.store.asset.featured"
                    linkText="messages.com.epicgames.plugin.store.asset.view_all_featured"
                    linkPath="showcase"
                    {...commonProps}
                />
                <HomeSection
                    className="marketplace-home-free"
                    data={free}
                    header="messages.com.epicgames.plugin.store.asset.free_for_month"
                    linkText="messages.com.epicgames.plugin.store.asset.view_all_free"
                    linkPath={{pathname: 'assets', search: this.getFreePageSearch()}}
                    {...commonProps}
                />
                <HomeSection
                    data={onSale}
                    header="messages.com.epicgames.plugin.store.asset.special_discounts"
                    linkText="messages.com.epicgames.plugin.store.asset.view_all_on_sale"
                    linkPath="on-sale"
                    {...commonProps}
                />
                <HomeSection
                    data={newReleases}
                    header="messages.com.epicgames.plugin.store.header.new_content"
                    linkText="messages.com.epicgames.plugin.store.asset.view_all_new_releases"
                    linkPath="new-content"
                    {...commonProps}
                />
            </Fragment>
        );
    }
}

HomePage.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    cmsPageData: PropTypes.object,
    getCmsPageData: PropTypes.func,
    getPageData: PropTypes.func,
    setConfig: PropTypes.func,
    messages: PropTypes.object,
    handleAddToCart: PropTypes.func,
    isAddingOffer: PropTypes.bool,
    currentOffer: PropTypes.string,
    offersInCart: PropTypes.array,
    isLauncher: PropTypes.bool,
    locale: PropTypes.string,
    location: PropTypes.object
};

export default HomePage;
