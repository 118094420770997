import React from 'react';
import styled from 'styled-components';
import root from 'window-or-global';
import {Redirect} from 'react-router';
import {generateRoutePath} from '@epic-core/common';
import {UnrealLoading} from 'epic-ue-loading';
import {useCouponsDetail, useCoupons, ImageType} from '../../shared/coupons/coupon.hooks';
import {markdownToHtml} from '../../components/markdown/Markdown';
import {CouponDetailSeo as CouponDetailSeoSection, SeoProps} from './CouponDetailSeo';

const CouponSection = styled.div`
    display: flex;
    margin-top: 3em;
    h3 {
        width: 200px;
        font-size: 18px;
    }
`;
const CouponDesc = styled.div`
    flex: 1;
    margin-left: 70px;
    > p {
        color: #ccc;
        font-size: 14px;
        padding-bottom: 3em;
        border-bottom: solid 1px rgba(100, 100, 100, 0.3);
    }
`;
const BannerWraper = styled.div`
    margin: 2em 0 3em 0;
    img {
        width: 100%;
        height: auto;
    }
`;

const CMSSection = styled.div`
    padding: 2em 2em 0 2em;
    background-color: #323232;
    border-radius: 5px;
    margin: 3em 0;
    & > div {
        border-bottom: solid 1px rgba(100, 100, 100, 0.3);
        padding: 2em 0;
        &:last-child {
            border-bottom: solid 0;
        }
        &:first-child {
            padding-top: 0;
        }
    }
    h4 {
        color: #f2f2f2;
        margin-bottom: 2em !important;
        font-weight: 600 !important;
    }
    p {
        color: #ccc;
        font-size: 14px;
        line-height: 1.8em;
    }
    table {
        margin-top: 10px;
        th {
            font-weight: 600;
            padding-right: 25px;
        }
    }
`;

const CMSArticleSection = styled.div`
    padding: 2em;
    background-color: #323232;
    border-radius: 5px;
    p {
        font-size: 14px;
        margin-bottom: 2em;
    }
`;

const Content = styled.div`
    margin: 2em auto;
`;

interface FAQ {
    answer: string;
    question: string;
}

type CMScontent = FAQ[] | string;

interface CmsContentProps {
    title?: string;
    content?: CMScontent;
    src?: string;
    altText?: string;
}

export const CouponDetail = ({eventSlug}: {eventSlug: string}) => {
    const {couponsDetailState, loading, loaded, error} = useCouponsDetail(eventSlug);
    const {couponsStore, loading: loadingCoupon} = useCoupons();
    const coupon = couponsStore.find(cp => cp.salesEvent.eventSlug === eventSlug) || {};
    const {voucherImages = {}} = coupon.salesEvent || {};
    const bannerImage =
        voucherImages.length &&
        voucherImages.find(image => image && image.type === ImageType.BANNER);

    const isServerSide = root.__server_side_render;
    if (isServerSide || loading || !loaded || loadingCoupon) {
        return <UnrealLoading fillViewHeight={false} />;
    }
    if (error) {
        if (error?.response?.status === 404) {
            return <Redirect to={generateRoutePath('error-404')} />;
        } else {
            return <Redirect to={generateRoutePath('error-500')} />;
        }
    }
    //@ts-ignore
    const {
        couponData: {about, faq, termsAndConditions},
        seo
    }: {
        couponData: {
            about: CmsContentProps;
            faq: CmsContentProps;
            termsAndConditions: CmsContentProps;
        };
        seo: SeoProps;
    } = couponsDetailState;
    return (
        <Content key="coupon-detail" className="container">
            <CouponDetailSeoSection seo={seo} />
            {bannerImage && bannerImage.url && (
                <BannerWraper>
                    <img src={bannerImage.url} alt="banner" />
                </BannerWraper>
            )}
            <CouponSection>
                <h3>{about.title}</h3>
                <CouponDesc>
                    <p>{about.content}</p>
                    {faq.content && faq.content.length && (
                        <CMSSection>
                            {faq?.content?.map(({question, answer}, index) => {
                                return (
                                    <div key={`section-${index}`}>
                                        <h4>{question}</h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: markdownToHtml(answer)
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </CMSSection>
                    )}
                </CouponDesc>
            </CouponSection>
            <CouponSection>
                <h3>{termsAndConditions.title}</h3>
                <CouponDesc>
                    <CMSArticleSection>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: markdownToHtml(termsAndConditions.content)
                            }}
                        />
                    </CMSArticleSection>
                </CouponDesc>
            </CouponSection>
        </Content>
    );
};
