/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

@connect(mapStateToProps, mapDispatchToProps)
class FreeView extends BasePageView {
    constructor(props) {
        const staticParams = {
            priceRange: '[0,0]'
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.free'
            },
            isPriceHidden: true,
            pageTitleCode: 'messages.com.epicgames.plugin.store.asset.free',
            descriptionCode: 'epic.ue.marketplace.meta.free'
        });
    }
}
export default withRouter(FreeView);
