import React, {Component} from 'react';
import {Message, Link} from '../index';
import {WishlistToggle} from '../wishlist/WishlistToggle';
import PurchaseButton from '../common/button/PurchaseButton';

class ShoppingCartView extends Component {
    renderCartView = ({coupons: {couponsStore, refreshCouponList} = {}}) => {
        if (couponsStore && couponsStore.length) {
            this.couponCodes = couponsStore;
            this.refreshCouponList = refreshCouponList;
        }
        const totalPrice = this.getTotalPrice();
        const totalDiscountPrice = this.getTotalDiscountPrice();
        return (
            <div className="shopping-cart" tabIndex={-1} onBlur={this.handleBlur}>
                <a
                    href="#"
                    className="cart-icon-wrapper"
                    onClick={this.toggleOpen}
                    title={this.getMessage(
                        'messages.com.epicgames.plugin.store.message.cart.shopping_cart'
                    )}>
                    <span className="icon">
                        <i className="shopping-cart-icon fa fa-shopping-cart" />
                        <i className="cart-amount">{this.getDisplayOfferCount()}</i>
                    </span>
                </a>
                <div className={`max-amount-alert ${this.isReachMaxLimit() ? '' : 'hidden'}`}>
                    <i className="fa fa-warning" />
                    &nbsp;&nbsp;
                    <Message
                        className="message"
                        code="messages.com.epicgames.plugin.store.message.cart.reach_max_limit"
                        args={this.getMaxLimitArgs()}
                    />
                </div>
                <div
                    className={`dropdown-content short ${this.isOpen() ? '' : 'hidden'} ${
                        !this.isLoadingCart() && this.getOfferCount() > 0 ? 'has-data' : ''
                    }`}>
                    <div
                        className={`cart-content-wrapper ${
                            this.isLoadingCart() ? 'invisible' : ''
                        }`}
                        style={{
                            visibility: this.isLoadingCart() ? 'hidden' : 'visible'
                        }}>
                        <div className={`has-offers ${this.getOfferCount() > 0 ? '' : 'hidden'}`}>
                            <section className="cart-header">
                                <span className="cart-title">
                                    {this.getMessage(
                                        'messages.com.epicgames.plugin.store.message.cart.shopping_cart'
                                    )}
                                    &nbsp;(
                                    <span className="cart-count">{this.getOfferCount()}</span>)
                                </span>
                            </section>
                            <section className="cart-offer-list">
                                {this.getOffers().map((off, idx) => (
                                    <div className="cart-item" key={`cart-item-${idx}`}>
                                        <Link to={`product/${off.urlSlug}`}>
                                            <div
                                                className="image-wrapper"
                                                style={{
                                                    backgroundImage: `url(${this.getDisplayImage(
                                                        off
                                                    )})`
                                                }}
                                            />
                                        </Link>
                                        <div className="info-wrapper">
                                            <Link to={`product/${off.urlSlug}`}>
                                                <p className="price">{off.title}</p>
                                            </Link>
                                            <p className="price">{this.getDisplayPrice(off)}</p>
                                            <WishlistToggle offerId={off.id} />
                                        </div>
                                        <div
                                            className="remove"
                                            onClick={this.handleRemoveOffer(off)}>
                                            <i className="fa fa-times" />
                                        </div>
                                    </div>
                                ))}
                            </section>
                            <section className="footer">
                                {this.getAppliednCoupon() && (
                                    <div className="total">
                                        {this.getMessage(
                                            'messages.com.epicgames.plugin.store.title.coupons.checkout'
                                        )}
                                        :
                                        <span className="price">
                                            {this.getTotalCouponDiscount()}
                                        </span>
                                    </div>
                                )}
                                <div className="total">
                                    {this.getMessage(
                                        'messages.com.epicgames.plugin.store.order.price'
                                    )}
                                    :
                                    <span className="price">
                                        {totalDiscountPrice !== totalPrice ? (
                                            <>
                                                <b>{totalPrice}</b> {totalDiscountPrice}
                                            </>
                                        ) : (
                                            totalPrice
                                        )}
                                    </span>
                                </div>

                                <div className="footer-btn-group">
                                    <div className="btn cart-close" onClick={this.toggleOpen}>
                                        {this.getMessage(
                                            'messages.com.epicgames.plugin.store.message.cart.continue_shopping'
                                        )}
                                    </div>

                                    <PurchaseButton className="btn checkout" onClick={this.handlePurchase}>
                                        {this.getMessage(
                                            'messages.com.epicgames.plugin.store.message.cart.check_out'
                                        )}
                                    </PurchaseButton>
                                </div>
                            </section>
                        </div>

                        <div
                            className={`no-offers ${
                                this.isLoadingCart() || this.getOfferCount() > 0 ? 'hidden' : ''
                            }`}>
                            <i className="fa fa-warning" />
                            &nbsp;
                            <Message code="messages.com.epicgames.plugin.store.message.cart.no_offers" />
                        </div>

                        <div className="sign-in-wrapper hidden">
                            <p>
                                {this.getMessage(
                                    'messages.com.epicgames.plugin.store.message.need_sign_in'
                                )}
                            </p>
                            <Message
                                className="btn sign-in"
                                code="messages.com.epicgames.plugin.store.message.sign_in"
                            />
                        </div>
                    </div>
                    <img
                        className={`cart-loading ${this.isLoadingCart() ? '' : 'hidden'}`}
                        src={this.getLoadingImage()}
                        alt=""
                    />
                </div>
            </div>
        );
    };
}
export default ShoppingCartView;
