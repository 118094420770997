import React from 'react';
import styled from 'styled-components';
import {useLocalization} from '@epic-core/localization';
import {useCountryCode} from 'epic-ue-shared';

interface Properties {
    discountStr?: string | null | undefined;
    priceStr?: string | null | undefined;
    hasDiscount?: boolean;
    asterisk?: boolean;
    currencyCode?: string | null | undefined;
}

const PriceWrap = styled.div`
    padding: 5px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    .asterisk {
        font-size: 16px;
        position: relative;
        top: -6px;
    }
    ${p => p.theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`;
const Discount = styled.div`
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    ${p => p.theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`;
const Original = styled.div`
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    &.has-discount {
        text-decoration: line-through;
        margin-right: 4px;
        opacity: 0.5;
        font-size: 14px;
        position: relative;
        top: -2px;
        left: -5px;
        ${p => p.theme.breakpoints.down('sm')} {
            font-size: 12px;
        }
    }
    ${p => p.theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`;

/**
 * Truncate the trailing .00 from the string to make the price and use FREE instead of 0
 * @param priceStr
 */
const getMarketingPrice = (
    priceStr: string | null | undefined,
    freeStr: string | null | undefined
) => {
    if (!priceStr) return '';

    let price = priceStr;

    if (freeStr && priceStr === '0') {
        price = freeStr;
    } else if (/\.00$/.test(priceStr)) {
        price = priceStr.replace(/\.00$/, '');
    } else if (/,00$/.test(priceStr)) {
        price = priceStr.replace(/,00$/, '');
    }
    return price;
};

const useCurrencyEl = (marketingPrice, currencyCode) => {
    const countryCode = useCountryCode();

    // Don't need USD if in US
    if (!currencyCode || countryCode === 'US') return null;

    //marketing price already has the currency in it
    if (marketingPrice.indexOf(currencyCode) !== -1) return null;

    //marketing price has characters before the dollar sign indicating currency
    if (/^[a-zA-z]+\$/.test(marketingPrice)) return null;

    return (
        <>
            &nbsp;
            <span className="currency-code">{currencyCode}</span>
        </>
    );
};

export const WishlistPrice = ({
    priceStr,
    discountStr,
    hasDiscount,
    asterisk,
    currencyCode
}: Properties) => {
    const getMessage = useLocalization();
    const freeStr = getMessage('messages.com.epicgames.plugin.store.asset.free') || '';
    const ogMarketingPrice = getMarketingPrice(priceStr, freeStr);
    const discountMarketingPrice = getMarketingPrice(discountStr, freeStr);
    const ogCurrencyEl = useCurrencyEl(ogMarketingPrice, currencyCode);
    const discountCurrencyEl = useCurrencyEl(discountMarketingPrice, currencyCode);

    const validDiscount = Boolean(hasDiscount && discountStr);
    return (
        <PriceWrap className="price-wrap">
            <Original className={`og ${validDiscount ? 'has-discount' : 'active'}`}>
                {ogMarketingPrice}
                {ogCurrencyEl}
            </Original>
            {validDiscount ? (
                <Discount className="discount active">
                    {discountMarketingPrice}
                    {discountCurrencyEl}
                </Discount>
            ) : (
                ''
            )}
            {asterisk ? <span className="asterisk">*</span> : ''}
        </PriceWrap>
    );
};
