import React from 'react';
import {Redirect} from 'react-router';
import {getPath} from '@epic-core/common';
import qs from 'query-string';
import {
    ErrorView,
    ErrorPage,
    AssetsView,
    NewContentView,
    OnSaleView,
    EventSaleView,
    HomePageView,
    CategoryView,
    FeaturedView,
    FreeView,
    AssetDetailView,
    VaultView,
    SellerAssetsView,
    WishlistPage,
    CouponListPage,
    CouponDetailPage
} from '../pages';

const redirectLocale = (destination, route) => {
    const path = getPath();
    let destPath = null;
    if (typeof destination === 'function') {
        destPath = destination(route.match.params);
    } else {
        destPath = destination;
    }
    let epicEnv;
    if (route && route.location && route.location.search) {
        epicEnv = qs.parse(route.location.search)['X-Epic-Env'];
    }
    let query = '';
    if (epicEnv) {
        query = `?X-Epic-Env=${epicEnv}`;
    }
    const redirectUrl = `${path}${route.match.params.locale}${destPath}${query}`;
    return <Redirect to={redirectUrl} />;
};

const goStore = redirectLocale.bind(null, '/store');
const goSlug = redirectLocale.bind(null, ({slug}) => `/product/${slug}`);
const goItemDetail = redirectLocale.bind(null, ({id}) => `/item-detail/${id}`);
const renderLearnAsset = props => <AssetDetailView {...props} isLearnAsset={true} />;

const getRoutes = function getRoutes() {
    return [
        {path: 'store', exact: true, component: HomePageView},
        {path: 'assets', exact: true, component: AssetsView},
        {path: 'content-cat/:parentCategory/:subCategory', exact: true, component: CategoryView},
        {path: 'content-cat/:category', exact: true, component: CategoryView},
        {path: 'free', exact: true, component: FreeView},
        {path: 'featured', exact: true, component: FeaturedView}, //old Showcase link
        {path: 'showcase', exact: true, component: FeaturedView},
        {path: 'on-sale', exact: true, component: OnSaleView},
        {path: 'event-sale', exact: true, component: EventSaleView},
        {path: 'new-content', exact: true, component: NewContentView},
        {path: 'vault', exact: true, component: VaultView},
        {path: 'profile/:sellerName', exact: true, component: SellerAssetsView},
        {path: 'error', exact: true, component: ErrorView},
        {path: 'error-404', exact: true, component: ErrorPage},
        {path: 'error-500', exact: true, component: ErrorPage},
        {path: 'item-detail/:id', exact: false, component: AssetDetailView},
        {path: 'item/:id', exact: true, render: goItemDetail},
        {path: 'product/:slug', exact: false, component: AssetDetailView},
        {path: 'content/:slug', exact: true, render: goSlug},
        {path: 'slug/:slug', exact: true, render: goSlug},
        {path: 'learn/:slug', exact: false, render: renderLearnAsset},
        {path: 'wishlist', exact: true, component: WishlistPage},
        {path: 'coupons', exact: true, component: CouponListPage},
        {path: 'coupons/:coupons', exact: true, component: CouponDetailPage},
        {path: ':slug', exact: true, render: goSlug},
        {path: '', render: goStore}
    ];
};

export default getRoutes;
