/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

@connect(mapStateToProps, mapDispatchToProps)
class FeaturedView extends BasePageView {
    constructor(props) {
        const staticParams = {
            featured: true
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.featured'
            },
            pageTitleCode: 'messages.com.epicgames.plugin.store.title.featured',
            descriptionCode: 'epic.ue.marketplace.meta.showcase'
        });
    }
}
export default withRouter(FeaturedView);
