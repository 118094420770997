import React, {useState, useCallback, useEffect} from 'react';
import {hot} from 'react-hot-loader';
import styled from 'styled-components';
import {UnrealLoading} from 'epic-ue-loading';
import {useWishlistItems, WISHLIST_ITEM_LIMIT} from '../../shared/wishlist.hooks';
import {WishlistItem} from '../../components/wishlist/WishlistItem';
import {usePageTitle} from '../../shared/pageTitle.hooks';
import {useLocalization} from '@epic-core/localization';
import root from 'window-or-global';
import {InputLabel, Select, MenuItem} from '@material-ui/core';
import {WishlistItem as WishlistItemType} from 'epic-graphql-types';
import Helmet from 'react-helmet';

const SORT_OPTIONS_ENUM = {
    ALPHABETICAL: 'alphabetical',
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
    RELEASE: 'release_date'
};

const sortOptions = [
    SORT_OPTIONS_ENUM.ALPHABETICAL,
    SORT_OPTIONS_ENUM.PRICE_ASC,
    SORT_OPTIONS_ENUM.PRICE_DESC,
    SORT_OPTIONS_ENUM.RELEASE
];

const Wishlist = styled.div`
    margin: 2em 30px 4em;
    ${p => p.theme.breakpoints.down('sm')} {
        margin: 2em 10px 4em;
    }

    h2,
    h3,
    h4 {
        text-align: center;
    }

    h3 {
        font-size: 20px;
    }

    .hit-limit-warn {
        margin-bottom: 2em;
    }

    .wishlist-item {
        button.wishlist-toggle:hover {
            ${p => p.theme.breakpoints.up('md')} {
                .MuiSvgIcon-root {
                    color: black;
                }
                background-color: #ffcd43;
            }
        }

        .MuiSvgIcon-root {
            color: white;
        }
    }

    #sortLabel {
        display: inline-block;
        margin-right: 1em;
        font-size: 14px;
    }
    .wishlist-sort {
        border-radius: 0;
        color: unset;
        #sortSelect {
            min-height: 24px;
            padding: 2px 24px 2px 8px;
        }
        svg.MuiSelect-iconOutlined {
            right: 0;
        }
        fieldset.MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.32);
        }
    }
`;

const Sort = styled.div`
    margin-bottom: 20px;
`;

const sortWishlistItems = (items: WishlistItemType[], sortType: string) => {
    items.sort((a, b) => {
        const offA = a.offer || {};
        const offB = b.offer || {};
        try {
            if (sortType === SORT_OPTIONS_ENUM.PRICE_ASC) {
                const pa = offA?.price?.totalPrice?.discountPrice;
                const pb = offB?.price?.totalPrice?.discountPrice;
                return pb - pa;
            } else if (sortType === SORT_OPTIONS_ENUM.PRICE_DESC) {
                const pa = offA?.price?.totalPrice?.discountPrice;
                const pb = offB?.price?.totalPrice?.discountPrice;
                return pa - pb;
            } else if (sortType === SORT_OPTIONS_ENUM.RELEASE) {
                const ta = new Date(offA?.effectiveDate).getTime();
                const tb = new Date(offB?.effectiveDate).getTime();
                return tb - ta;
            }
        } catch (e) {
            console.warn('Failed to sort offer');
        }
        const ta = offA?.title;
        const tb = offB?.title;
        return ta.localeCompare(tb);
    });
    return items;
};

const WishlistPage = () => {
    const [sort, setSort] = useState(SORT_OPTIONS_ENUM.ALPHABETICAL);
    const {wishlistItemsStore, loading, loaded} = useWishlistItems();
    const {setPageTitle} = usePageTitle();

    const getMessage = useLocalization();
    const hitLimit = wishlistItemsStore.length >= WISHLIST_ITEM_LIMIT;
    const isServerSide = root.__server_side_render;

    useEffect(() => {
        setPageTitle('messages.com.epicgames.plugin.store.title.wishlist');
        return () => {
            setPageTitle('', true);
        };
    }, []);

    const onSortChange = useCallback(
        e => {
            const value = e.target.value;
            setSort(value);
        },
        [setSort]
    );

    if (isServerSide || loading || (!loaded && !wishlistItemsStore.length)) {
        return (
            <Wishlist>
                <UnrealLoading fillViewHeight={false} />
            </Wishlist>
        );
    }

    const sortedItems = sortWishlistItems(wishlistItemsStore, sort);
    const metaTitle = `${getMessage(
        'messages.com.epicgames.plugin.store.title.wishlist'
    )} ${getMessage('messages.com.epicgames.plugin.store.title.suffix')}`;

    return (
        <Wishlist>
            <Helmet title={metaTitle} meta={[{name: 'robots', content: 'noindex,nofollow'}]} />
            {hitLimit ? (
                <div className="hit-limit-warn">
                    <h3>{getMessage('epic.marketplace.wishlist.exceed_max_size_title')}</h3>
                    <h4>{getMessage('epic.marketplace.wishlist.exceed_max_size_description')}</h4>
                </div>
            ) : (
                ''
            )}
            {!sortedItems.length ? (
                <h4>{getMessage('epic.marketplace.wishlist.empty')}</h4>
            ) : (
                <Sort>
                    <InputLabel id="sortLabel">
                        {getMessage('epic.marketplace.browse.sort_by')}
                    </InputLabel>
                    <Select
                        className="wishlist-sort"
                        labelId="sortLabel"
                        id="sortSelect"
                        value={sort}
                        onChange={onSortChange}>
                        {sortOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {getMessage(`epic.marketplace.sort_type.${option}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </Sort>
            )}
            {sortedItems.map(item => {
                const offer = item.offer || {};
                return <WishlistItem key={`wishlist-${offer.id}`} {...offer} />;
            })}
        </Wishlist>
    );
};

export default hot(module)(WishlistPage);
