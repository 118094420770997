/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';

@connect(mapStateToProps, mapDispatchToProps)
class OnSaleView extends BasePageView {
    constructor(props) {
        const staticParams = {
            saleType: 'regular|event'
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'epic.ue.marketplace.categories.onSale'
            },
            pageTitleCode: 'epic.ue.marketplace.categories.onSale',
            descriptionCode: 'epic.ue.marketplace.meta.sale'
        });
    }
}
export default withRouter(OnSaleView);
