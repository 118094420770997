import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h1`
    font-size: inherit;
    margin-bottom: 2em;
`;

type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface MarkdownHeadingProps {
    level: number;
    children: React.ReactNode;
}

const MarkdownHeading: React.FC<MarkdownHeadingProps> = ({
    level,
    children
}: MarkdownHeadingProps) => {
    const heading = `h${level}` as Heading;
    return <StyledHeading as={heading}>{children}</StyledHeading>;
};

export default MarkdownHeading;
